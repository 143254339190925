import React, {useEffect, Suspense} from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {useTranslation} from "react-i18next";

import * as actions from './store/actions/index';
import PrivateRoute from "./components/Auth/PrivateRoute";
import Spinner from "./components/UI/Spinner/Spinner";
import Layout from './hoc/Layout/Layout';
import Home from "./components/Home/Home";
import NewsList from "./containers/News/NewsList";
import DynFormList from "./containers/DynForms/DynFormList";
import NotificationList from "./containers/Notifications/NotificationList";
import Logout from "./containers/Auth/Logout/Logout";
import PageNotFound from "./components/Navigation/PageNotFound/PageNotFound";
import Profile from "./containers/Profile/Profile";
import FamilyComposition
  from "./containers/FamilyComposition/FamilyComposition";
import DocumentList from "./containers/Documents/DocumentList";
import InvoiceList from "./containers/Invoices/InvoiceList";
import QueueList from "./containers/Queue/QueueList";
import RequestList from "./containers/Requests/RequestList";
import AppointmentList from "./containers/Appointments/AppointmentList";
import DynForm from "./containers/DynForms/DynForm";

// Lazy loading of components, also used for async components.
const ForgotPassword = React.lazy(() => {
  return import('./containers/Auth/ForgotPassword/ForgotPassword');
});

const Login = React.lazy(() => {
  return import('./containers/Auth/Login/Login');
});

const Register = React.lazy(() => {
  return import('./containers/Auth/Register/Register');
});

const App = props => {
  const { onTryAutoSignIn, onInitApp } = props;
  const {t} = useTranslation('routes');

  useEffect(() => {
    onInitApp();
    onTryAutoSignIn();
  }, [onTryAutoSignIn, onInitApp]);

  // useEffect(() => {
  //   i18n.changeLanguage('en')
  // }, [])

  let routes;
  if (props.isAuthenticated) {
    routes = (
      <Switch>
        <PrivateRoute path={t("routes:/")} exact render={props => <Home {...props} />} />
        <PrivateRoute path={t("routes:/my-information")} exact={true} render={props => <Profile {...props} />} />
        <PrivateRoute path={t("routes:/my-family")} exact={true} render={props => <FamilyComposition {...props} />} />
        <PrivateRoute path={t("routes:/my-queue")} exact={true} render={props => <QueueList {...props} />} />
        <PrivateRoute path={t("routes:/my-requests")} exact={true} render={props => <RequestList {...props} />} />
        <PrivateRoute path={t("routes:/my-documents")} exact={true} render={props => <DocumentList {...props} />} />
        <PrivateRoute path={t("routes:/my-invoices")} exact={true} render={props => <InvoiceList {...props} />} />
        <PrivateRoute path={t("routes:/my-appointments")} exact={true} render={props => <AppointmentList {...props} />} />
        <PrivateRoute path={t("routes:/info")} exact={true} render={props => <NewsList {...props} />} />
        <PrivateRoute path={t("routes:/add")} exact={true} render={props => <DynFormList {...props} />} />
        <PrivateRoute path={`${t("routes:/add")}/:formCategory/:formId`} exact={true} render={props => <DynForm {...props} />} />
        <PrivateRoute path={t("routes:/notifications")} exact={true} render={props => <NotificationList {...props} />} />
        <PrivateRoute path={t("routes:/logout")} exact={true} render={props => <Logout {...props} />} />
        <Route path={t("routes:/login")} exact={true} render={props => <Login {...props} />} />
        <PrivateRoute path={"*"} render={props => <PageNotFound {...props} />} />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route path={t("routes:/login")} exact={true} render={props => <Login {...props} />} />
        <Route path={t("routes:/register")} exact={true} render={props => <Register {...props} />} />
        <Route path={t("routes:/password-reset")} exact={true} render={props => <ForgotPassword {...props} />} />
        <Redirect to={t("routes:/login")} />
      </Switch>
    );
  }

  return (
    <div className="pageWrap">
      <Layout>
        <Suspense fallback={<Spinner />}>
            {routes}
        </Suspense>
      </Layout>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onInitApp: () => dispatch( actions.initApp() ),
    onTryAutoSignIn: () => dispatch( actions.authCheckState() ),
  };
};

export default
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(App)
  );
