import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/store/utility';

const initialState = {
  error: null,
  loading: false,
  documentList: [],
  currentDocument: null,
};

const documentsGetStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const documentsListGetSuccess = (state, action) => {
  return updateObject( state, {
    error: null,
    loading: false,
    documentList: action.documentList
  });
};

const documentsGetFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const documentGetSuccess = (state, action) => {
  return updateObject(state, {
    currentDocument: action.currentDocument
  })
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.DOCUMENTLIST_GET_START: return documentsGetStart(state, action);
    case actionTypes.DOCUMENTLIST_GET_SUCCESS: return documentsListGetSuccess(state, action);
    case actionTypes.DOCUMENTLIST_GET_FAIL: return documentsGetFail(state, action);
    case actionTypes.DOCUMENT_GET_START: return documentsGetStart(state, action);
    case actionTypes.DOCUMENT_GET_SUCCESS: return documentGetSuccess(state, action);
    case actionTypes.DOCUMENT_GET_FAIL: return documentsGetFail(state, action);
    default:
      return state;
  }
};

export default reducer;
