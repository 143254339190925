import React from 'react';

import './flexTblRow.scss';

const FlexTblRow = props => {

  return (
    <div className="listRow">
      {props.children}
    </div>
  )
}

export default FlexTblRow;
