import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from "prop-types";

import * as actions from '../../../store/actions/index';
import ConfirmModal from "../../../components/UI/Modal/ConfirmModal";

import './logout.scss';

const Logout = props => {
  const { t } = useTranslation('forms');
  const { onLogout } = props;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <span className="nav-iconized" onClick={handleShow}>
        <i className="material-icons">login</i>
        <span>{t('links:Log out')}</span>
      </span>

      <ConfirmModal
        show={show}
        size="md"
        handleClose={handleClose}
        title={t('Log out?')}
        closeText={t('links:Close')}
        showCloseButton
        confirmText={t('links:Log out')}
        confirmColor="danger"
        confirmHandler={onLogout}
      >
        <p>{t('Do you really want to log out?')}</p>
      </ConfirmModal>
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.logout())
  };
};

Logout.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Logout);
