import React from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";

// import './confirmModal.scoped.scss';

const confirmModal = props => {

  return (
    <Modal size={props.size} show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        {props.showCloseButton
          ? (
            <Button variant="secondary" onClick={props.handleClose}>
              {props.closeText}
            </Button>
          )
          : null
        }
        <Button variant={props.confirmColor} onClick={props.confirmHandler}>
          {props.confirmText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

confirmModal.propTypes = {
  size: PropTypes.string,
  show: PropTypes.bool,
  title: PropTypes.string,
  showCloseButton: PropTypes.bool,
  closeText: PropTypes.string,
  confirmColor: PropTypes.string,
  confirmText: PropTypes.string,
  confirmHandler: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

// use react memo with second argument to define when this component should be rerendered.
// It's the inverse of shouldComponentUpdate, so memo should be triggered when the props are equal.
export default React.memo(confirmModal, (prevProps, nextProps) => {
  return nextProps.show === prevProps.show &&
    nextProps.handleClose === prevProps.handleClose &&
    nextProps.title === prevProps.title &&
    nextProps.closeText === prevProps.closeText &&
    nextProps.confirmText === prevProps.confirmText &&
    nextProps.confirmHandler === prevProps.confirmHandler &&
    nextProps.showCloseButton === prevProps.showCloseButton &&
    nextProps.confirmColor === prevProps.confirmColor &&
    nextProps.children === prevProps.children
});
