const en = {
  "/": "/en",
  "/login": "/en/login",
  "/register": "/en/register",
  "/info": "/en/information",
  "/add": "/en/add",
  "/notifications": "/en/notifications",
  "/help": "/en/help",
  "/logout": "/en/logout",
  "/my-information": "/en/my-information",
  "/my-family": "/en/my-family",
  "/my-queue": "/en/my-queue",
  "/my-requests": "/en/my-requests",
  "/my-documents": "/en/my-documents",
  "/my-invoices": "/en/my-invoices",
  "/my-appointments": "/en/my-appointments",
  "/password-reset": "/en/password-reset",
  "/not-found": "/en/not-found",

}

export default en;
