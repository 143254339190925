import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Helmet from "react-helmet";

import * as actions from "../../store/actions";
import sociopackConfig from "../../config/sociopack.config";
import Spinner from "../../components/UI/Spinner/Spinner";
import Breadcrumbs from "../../components/Navigation/Breadcrumb/Breadcrumb";
import ErrorMessage from "../../components/UI/ErrorMessage/ErrorMessage";

import './profile.scoped.scss';
import Button from "react-bootstrap/Button";

const Profile = props => {
  const {t} = useTranslation('pages');
  const {onGetProfile} = props;

  useEffect(() => {
    onGetProfile();
  }, [onGetProfile]);

  const changeRequest = (categoryName, formId) => {
    const categoryKey = categoryName.replace(/\s+/g, '').toLowerCase();
    props.history.push(`${t('routes:/add')}/${categoryKey}/${formId}`);
  }

  let profileContent = <Spinner />;

  let errorMessage = null;
  if (props.error) {
    errorMessage = <ErrorMessage errorMsg={props.errorMessage} />;
  }

  if (!props.loading && props.profile !== null) {
    const dateOfBirth = new Date(props.profile.dateOfBirth);
    const dateOfBirthFormatted = dateOfBirth.getDate() + '-' + (dateOfBirth.getMonth()+1) + '-' + dateOfBirth.getFullYear();

    profileContent =
      <>
        <div className="profile">
          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Name")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <span className="value">{props.profile.name}</span>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Gender")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <span className="value">{props.enums[props.profile.gender.key][props.profile.gender.value].code}</span>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Social security number")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <span className="value">{props.profile.nationalRegistryNumber}</span>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Birth date")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <span className="value">{dateOfBirthFormatted}</span>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Birth place")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <span className="value">{props.profile.placeOfBirth}</span>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Nationality")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <span className="value">{props.profile.nationality}</span>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Address")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <span className="value">{props.profile.address}</span>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Civil state")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <span className="value">{props.profile.civilState}</span>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Co parenting")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <span className="value">{props.profile.coParenting}</span>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Occupation")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <span className="value">{props.profile.occupation}</span>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Relation")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <span className="value">{props.profile.relation}</span>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Visitation rights")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <span className="value">{props.profile.visitationRights}</span>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Email addresses")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <div className="values">
                {props.profile.emailAddresses.map((email, index) => {
                  return (
                    <div key={index}>
                      <span className="description">{email.description}</span>
                      <span className="email">{email.email}</span>
                      <span className="note">{email.note}</span>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Income")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <div className="values">
                {props.profile.income.map((inc, index) => {
                  return (
                    <div key={index}>
                      <span className="year">{inc.year}</span>
                      <span className="amount">{inc.amount}</span>
                      <span className="note">{inc.note}</span>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Phone numbers")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <div className="values">
                {props.profile.phoneNumbers.map((number, index) => {
                  return (
                    <div key={index}>
                      <span className="description">{number.description}</span>
                      <span className="number">{number.number}</span>
                      <span className="note">{number.note}</span>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>

          <Row className="info-row" noGutters={true}>
            <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
              <span className="label">{t("Note")}</span>
            </Col>
            <Col xs={12} sm={7} md={8} lg={9} className="info-val">
              <span className="value">{props.profile.note}</span>
            </Col>
          </Row>
        </div>
        {/* <div className="form-actions">
          <Button onClick={() => changeRequest(props.profile.editForm.category, props.profile.editForm.id)}>{t('Change profile data')}</Button>
        </div> */}
      </>
  }

  const breadcrumbsConfig = [
    {to: t('routes:/'), linkText: t('Home')},
    {active: true, linkText: t('My information')},
  ];

  return (
    <>
      <Helmet>
        <title>{t('Profile')}{sociopackConfig.app.titleSuffix}</title>
        <link rel="canonical" href={sociopackConfig.app.baseURL + t('routes:/my-information')} />
        <meta name="description" content="Profile data" />
      </Helmet>
      <h1>{t('Profile')}</h1>
      <Breadcrumbs config={breadcrumbsConfig} />
      {errorMessage}
      <div>
        {profileContent}
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    profile: state.profile.profile,
    loading: state.profile.loading,
    error: state.profile.error !== null,
    errorMessage: (state.profile.error !== null) ? state.profile.error.data : null,
    enums: state.app.enums,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetProfile: () => dispatch(actions.getProfile()),
  };
};

Profile.propTypes = {
  profile: PropTypes.object,
  loading: PropTypes.bool,
  enums: PropTypes.object,
  onGetProfile: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
