import React, {useEffect} from "react";
import {connect} from "react-redux";
import { pdfjs } from 'react-pdf';
import PropTypes from 'prop-types';
import createDOMPurify from 'dompurify'
import {JSDOM} from "jsdom";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";

import * as actions from "../../store/actions";
import Spinner from "../../components/UI/Spinner/Spinner";
import PdfViewerModal from "../../components/UI/Modal/PdfViewerModal";
import ErrorMessage from "../../components/UI/ErrorMessage/ErrorMessage";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const window = (new JSDOM('')).window
const DOMPurify = createDOMPurify(window)

const NewsListItem = props => {
  const {onGetAttachment} = props;

  useEffect(() => {
    onGetAttachment(props.attachmentUrl, props.attachmentType);
  }, [onGetAttachment, props.attachmentType, props.attachmentUrl]);

  let pdfViewer = <Spinner />;

  let errorMessage = null;
  if (props.error) {
    errorMessage = <ErrorMessage errorMsg={props.errorMessage} />;
  }
  if (!props.error && !props.loading && (props.attachmentType in props.attachments) && (props.attachmentId in props.attachments[props.attachmentType])) {
    pdfViewer = <PdfViewerModal
      title={props.title}
      document={props.attachments[props.attachmentType][props.attachmentId].document.data}
    />
  }

  return (
    <Col xs={12} sm={4} md={3} className="news-item-col">
      <Card className="news-item">
        <div className="image-wrap">
          <Card.Img className="thumbnail" variant="top" src={props.image} />
          <Badge variant="primary">{props.category}</Badge>
        </div>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Text as="div" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.content) }} />
          {errorMessage}
          {pdfViewer}
        </Card.Body>
      </Card>
    </Col>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.attachments.loading,
    error: state.attachments.error !== null && state.attachments.error !== undefined,
    errorMessage: (state.attachments.error !== null && state.attachments.error !== undefined) ? state.attachments.error.data : null,
    attachments: state.attachments.attachments,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAttachment: (url, type) => dispatch(actions.getAttachment(url, type)),
  };
}

NewsListItem.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  attachment: PropTypes.string, 
  loading: PropTypes.bool,
  error: PropTypes.bool,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsListItem);

