import React from 'react';
import PropTypes from "prop-types";
import Collapse from "react-bootstrap/Collapse";

import SidebarMenuItems from "../SidebarNavigation/SidebarMenuItems";

import './sidebarMenu.scss';

const sidebarMenu = props => {
  return (
    <>
      <div className="sidebarMenu">
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <Collapse in={props.isOpen}>
            <div className="sidebar-collapse" id="basic-navbar-nav">
              <SidebarMenuItems />
            </div>
          </Collapse>
        </nav>
      </div>
    </>
  );
}

sidebarMenu.propTypes = {
  isOpen: PropTypes.bool,
};

export default sidebarMenu;
