import React from "react";
import { useTranslation } from 'react-i18next';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import './pageNotFound.scoped.scss';

const PageNotFound = props => {
  const { t } = useTranslation('pages');

  return (
    <Container className="page-not-found">
      <Row>
        <Col xs lg="12">
          <h1>{t("Page Not Found")}</h1>
        </Col>
      </Row>
    </Container>
  )
}

export default PageNotFound;
