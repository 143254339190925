import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet'
import {connect} from "react-redux";
import PropTypes from "prop-types";

import * as actions from "../../store/actions";
import sociopackConfig from "../../config/sociopack.config";
import QueueListItem from "./QueueListItem";
import Spinner from "../../components/UI/Spinner/Spinner";
import FlexTbl from "../../components/UI/FlexTable/FlexTbl";
import Breadcrumbs from "../../components/Navigation/Breadcrumb/Breadcrumb";
import ErrorMessage from "../../components/UI/ErrorMessage/ErrorMessage";

import './queueList.scoped.scss';

const QueueList = props => {
  const {t} = useTranslation('pages');
  const {onGetQueueList} = props;

  useEffect(() => {
    onGetQueueList();
  }, [onGetQueueList]);

  let queueList = <Spinner />;

  let errorMessage = null;
  if (props.error) {
    errorMessage = <ErrorMessage errorMsg={props.errorMessage} />;
  }

  // const tblConfig = {
  //   headers: [
  //     {name: 'Name', type: 'text'},
  //     {name: 'Registration N°', type: 'text'},
  //     {name: 'Registration Date', type: 'date'},
  //     {name: 'Status', type: 'text'},
  //     {name: 'Position', type: 'number'},
  //   ]
  // }
  const tblConfig = {
    headers: [
      {name: 'Inschrijvingsnr', type: 'text'},
      {name: 'Positie', type:'number'},
      {name: 'Slaapkamers', type: 'number'},
      {name: 'Min. bezetting', type: 'number'},
      {name: 'Max. bezetting', type: 'number'},
      {name: 'Schrappingsdatum', type: 'date'},
      {name: 'Gemeente', type: 'text'},
      {name: 'Wijk', type: 'text'},
      {name: 'Groep', type: 'text'},
      {name: 'Type', type: 'text'},
      {name: 'InschrNr', type: 'text'}
    ]
  }

  // inschrijvingsnr: PropTypes.string,
  // positie: PropTypes.number,
  // slaapkamers: PropTypes.number,
  // minBezetting: PropTypes.number,
  // maxBezetting: PropTypes.number,
  // schrDatum: PropTypes.string,
  // gemeente: PropTypes.string,
  // wijk: PropTypes.string,
  // groep: PropTypes.string,
  // type: PropTypes.string,
  // inschrnr: PropTypes.string

  
  if (!props.loading) {
    queueList = <p>{t('No queue yet')}</p>
    if (props.queueList.length > 0) {
      queueList = props.queueList.map(queue => {
        return <QueueListItem 
            key={queue.id}           
            inschrijvingsnr={queue.inschrijvingsNr}
            positie={queue.volgnrPrioriteit}
            slaapkamers={queue.aantalSlaapkamers}
            minBezetting={queue.minimumBezetting}
            maxBezetting={queue.maximumBezetting}
            schrDatum={queue.schrappingsDatum}
            gemeente={queue.gemeente}
            wijk={queue.wijk}
            groep={queue.groep}
            type={queue.type}
            inschrnr={queue.inschrNr}
            tblConfig={tblConfig}
        />
      });
    }
  }

  const breadcrumbsConfig = [
    {to: t('routes:/'), linkText: t('Home')},
    {active: true, linkText: t('My queue')},
  ];

  return (
      <>
        <Helmet>
          <title>{t('Queue')}{sociopackConfig.app.titleSuffix}</title>
          <link rel="canonical" href={sociopackConfig.app.baseURL + t('routes:/my-queue')} />
          <meta name="description" content={t('Queue')} />
        </Helmet>
        <h1>{t('Queue')}</h1>
        <Breadcrumbs config={breadcrumbsConfig} />
        {errorMessage}
        <div className="queueList">
          <FlexTbl cls={`flex-cols-${tblConfig.headers.length}`}>
            <FlexTbl.Head>
              <FlexTbl.Row>
                {tblConfig.headers.map((header, index) => {
                  return <FlexTbl.Data cls={`data-col-${index+1}`} key={index} label={t(header.name)}/>
                })}
              </FlexTbl.Row>
            </FlexTbl.Head>
            <FlexTbl.Body>
              {queueList}
            </FlexTbl.Body>
          </FlexTbl>
        </div>
      </>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.queue.loading,
    error: state.queue.error !== null,
    errorMessage: (state.queue.error !== null) ? state.queue.error.data : null,
    queueList: state.queue.queueList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetQueueList: () => dispatch( actions.getQueueList()),
  };
}

QueueList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  queueList: PropTypes.array,
  onGetQueueList: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueueList);
