import React from 'react';

import sociopackConfig from '../../config/sociopack.config'

import './logo.scoped.scss';
import PropTypes from "prop-types";

const logo = props => (
  <span className="logo" style={{height: props.height}}>
    <img className="img-fluid" src={props.logo} alt={sociopackConfig.app.name + ' logo'} />
  </span>
);

logo.propTypes = {
  logo: PropTypes.string,
  height: PropTypes.string,
};

export default logo;
