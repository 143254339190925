import axios from '../../config/axios.config';

import * as actionTypes from './actionTypes';

export const getNotificatonListStart = () => {
  return {
    type: actionTypes.NOTIFICATIONLIST_GET_START
  };
};

export const getNotificatonListSuccess = notifications => {
  return {
    type: actionTypes.NOTIFICATIONLIST_GET_SUCCESS,
    notifications: notifications
  };
};

export const getNotificatonListFail = error => {
  return {
    type: actionTypes.NOTIFICATIONLIST_GET_FAIL,
    error: error,
  };
};

export const getNotificationList = () => {

  return dispatch => {
    dispatch(getNotificatonListStart());

    axios.get('/portal/notifications')
      .then(response => {
        dispatch(getNotificatonListSuccess(response.data.resources));
      })
      .catch(error => {
        dispatch(getNotificatonListFail(error.response));
      });
  };
};

export const setNotificationToRead = (id) => {

  return dispatch => {
    axios.post(`/portal/notifications/${id}`);
  };
};
