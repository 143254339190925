import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/store/utility';

const initialState = {
  error: null,
  loading: false,
  registerRedirectPath: '/',
  registerSuccessful: null
};

const registerStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true, registerSuccessful: null } );
};

const registerSuccess = (state, action) => {
  return updateObject( state, {
    error: null,
    loading: false,
    registerSuccessful: true
  });
};

const registerFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false,
    registerSuccessful: null
  });
};

const registerReset = (state, action) => {
  return updateObject( state, {
    registerSuccessful: null
  });
}

const setRegisterRedirectPath = (state, action) => {
  return updateObject(state, { registerRedirectPath: action.path })
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.REGISTER_START: return registerStart(state, action);
    case actionTypes.REGISTER_SUCCESS: return registerSuccess(state, action);
    case actionTypes.REGISTER_FAIL: return registerFail(state, action);
    case actionTypes.REGISTER_RESET: return registerReset(state, action);
    case actionTypes.SET_REGISTER_REDIRECT_PATH: return setRegisterRedirectPath(state,action);
    default:
      return state;
  }
};

export default reducer;
