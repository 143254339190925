import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import {I18nextProvider} from "react-i18next";
import store from './store/store'
import i18next from "i18next";
import './config/i18n';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/scss/global/global.scss';

// class DebugRouter extends BrowserRouter {
//   constructor(props){
//     super(props);
//     console.log('initial history is: ', JSON.stringify(this.history, null,2))
//     this.history.listen((location, action)=>{
//       console.log(
//         `The current URL is ${location.pathname}${location.search}${location.hash}`
//       )
//       console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null,2));
//     });
//   }
// }

const app = (
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
        {/*<DebugRouter>*/}
          <BrowserRouter>
            <App />
          </BrowserRouter>
        {/*</DebugRouter>*/}
        </I18nextProvider>
      </Provider>
);

ReactDOM.render( app, document.getElementById( 'root' ) );
serviceWorker.unregister();
