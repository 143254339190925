import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet'
import {connect} from "react-redux";
import PropTypes from "prop-types";

import * as actions from "../../store/actions";
import sociopackConfig from "../../config/sociopack.config";
import Spinner from "../../components/UI/Spinner/Spinner";
import NewsListItem from "./NewsListItem";
import ErrorMessage from "../../components/UI/ErrorMessage/ErrorMessage";
import Row from "react-bootstrap/Row";

import './newsList.scss';

const NewsList = props => {
  const { t } = useTranslation('pages');
  const {onGetNewsList} = props;

  useEffect(() => {
    onGetNewsList();
  }, [onGetNewsList]);

  let newsArticles = <Spinner />;

  let errorMessage = null;
  if (props.error) {
    errorMessage = <ErrorMessage errorMsg={props.errorMessage} />;
  }

  if (!props.loading) {
    newsArticles = <p>{t('No news for you yet')}</p>
    if (props.newsArticles.length > 0) {
      newsArticles = props.newsArticles.map(newsArticle => {
        return <NewsListItem
          key={newsArticle.id}
          title={newsArticle.title}
          content={newsArticle.content}
          image={newsArticle.image}
          category={newsArticle.category}
          attachmentId={newsArticle.attachmentId}
          attachmentUrl={newsArticle.attachmentHref}
          attachmentType={props.enums[newsArticle.attachmentType.key][newsArticle.attachmentType.value].code}
          published={newsArticle.published}
        />
      });
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('News')}{sociopackConfig.app.titleSuffix}</title>
        <link rel="canonical" href={sociopackConfig.app.baseURL + t('routes:/info')} />
        <meta name="description" content={t('News')} />
      </Helmet>
      <h1>{t('News')}</h1>
      {errorMessage}
      <div className="newsList">
        <Row>
          {newsArticles}
        </Row>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.news.loading,
    error: state.news.error !== null,
    errorMessage: (state.news.error !== null) ? state.news.error.data : null,
    newsArticles: state.news.newsArticles,
    enums: state.app.enums,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetNewsList: () => dispatch( actions.getNewsList() ),
  };
}

NewsList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  newsArticles: PropTypes.array,
  onGetNewsList: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsList);
