import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/store/utility';

const initialState = {
  error: null,
  loading: false,
  notifications: []
};

const notificationListGetStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const notificationListGetSuccess = (state, action) => {
  return updateObject( state, {
    error: null,
    loading: false,
    notifications: action.notifications
  } );
};

const notificationListGetFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.NOTIFICATIONLIST_GET_START: return notificationListGetStart(state, action);
    case actionTypes.NOTIFICATIONLIST_GET_SUCCESS: return notificationListGetSuccess(state, action);
    case actionTypes.NOTIFICATIONLIST_GET_FAIL: return notificationListGetFail(state, action);
    default:
      return state;
  }
};

export default reducer;