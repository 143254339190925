import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/store/utility';

const initialState = {
  error: null,
  loading: false,
  invoiceList: [],
  currentInvoice: null,
};

const invoicesGetStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const invoiceListGetSuccess = (state, action) => {
  return updateObject( state, {
    error: null,
    loading: false,
    invoiceList: action.invoiceList
  });
};

const invoicesGetFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const invoiceGetSuccess = (state, action) => {
  return updateObject(state, {
    currentInvoice: action.currentInvoice
  })
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.INVOICELIST_GET_START: return invoicesGetStart(state, action);
    case actionTypes.INVOICELIST_GET_SUCCESS: return invoiceListGetSuccess(state, action);
    case actionTypes.INVOICELIST_GET_FAIL: return invoicesGetFail(state, action);
    case actionTypes.INVOICE_GET_START: return invoicesGetStart(state, action);
    case actionTypes.INVOICE_GET_SUCCESS: return invoiceGetSuccess(state, action);
    case actionTypes.INVOICE_GET_FAIL: return invoicesGetFail(state, action);
    default:
      return state;
  }
};

export default reducer;
