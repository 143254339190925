import React from 'react';
import BootstrapSpinner from 'react-bootstrap/Spinner'

import './spinner.scss';

const Spinner = () => (
  <BootstrapSpinner animation="border" className="loader" variant="primary" />
);

export default Spinner;
