import React from "react";

import FlexTblHead from "./FlexTblHead";
import FlexTblData from "./FlexTblData";
import FlexTblBody from "./FlexTblBody";
import FlexTblRow from "./FlexTblRow";

import './flexTbl.scss';

const FlexTbl = props => {

  return (
    <div className={`flexTable ${props.cls}`}>
      {props.children}
    </div>
  );
}

FlexTbl.Head = FlexTblHead;
FlexTbl.Data = FlexTblData;
FlexTbl.Body = FlexTblBody;
FlexTbl.Row = FlexTblRow;

export default FlexTbl;
