import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {LinkContainer} from "react-router-bootstrap";

const Breadcrumbs = ({config}) => {
  const generatedBreadcrumbs = config.map((crumb, index) => {
    if (crumb.active) {
      return <Breadcrumb.Item key={index} active>{crumb.linkText}</Breadcrumb.Item>;
    } else {
      return (
        <LinkContainer key={index} to={crumb.to} exact>
          <Breadcrumb.Item>{crumb.linkText}</Breadcrumb.Item>
        </LinkContainer>
      );
    }
  });

  return (
    <Breadcrumb>
      {generatedBreadcrumbs}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
