import axios from 'axios';
import store from '../store/store';
import sociopackConfig from "./sociopack.config";

axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const instance = axios.create({
  baseURL: sociopackConfig.api.baseURL,
  headers: {
    common: {},
    post: {
      'Content-Type': 'application/json'
    }
  }
});

instance.interceptors.request.use(function (config) {
  const token = store.getState().auth.token;
  if (token !== null) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default instance;
