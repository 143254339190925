import React from "react";
import PropTypes from "prop-types";
import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

const RequestListItem = props => {

  let requestValues = '';
  if (props.form.length > 0) {
    requestValues = props.form.map((formElement, index) => {
      return (
        <Row key={index} className="info-row" noGutters={true}>
          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
            <span className="label">{formElement.label}</span>
          </Col>
          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
            <span className="value">{formElement.value}</span>
          </Col>
        </Row>
      );
    });
  }

  return (
    <Accordion>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={props.index.toString()}>
          <Badge variant='secondary'>{props.category}</Badge> {' '}
            {props.name}: {' '} 
            {props.subject}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={props.index.toString()}>
          <Card.Body>
            <p>{props.description}</p>
            {requestValues}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

RequestListItem.propTypes = {
  name: PropTypes.string,
  category: PropTypes.string,
  description: PropTypes.string,
  subject: PropTypes.string,
  form: PropTypes.array,
}

export default RequestListItem;
