import React from "react";
import Nav from "react-bootstrap/Nav";

import NavigationItem from "../NavigationItem/NavigationItem";
import { useTranslation } from "react-i18next";
import "./sidebarMenuItems.scoped.scss";

const SidebarMenuItems = props => {
  const { t } = useTranslation('links');
  const classes = ["sidebarNavItems", "navbar-nav", "ml-auto"];

  return (
    <>
      <Nav as="ul" className={classes.join(' ')}>
        <NavigationItem
          link={t("routes:/")}
          iconName="home"
          exact
        >{t("Home")}</NavigationItem>
        <NavigationItem
          link={t("routes:/info")}
          iconName="info"
          exact
        >{t("Info")}</NavigationItem>
        <NavigationItem
          link={t("routes:/add")}
          iconName="post_add"
          exact
        >{t("Add")}</NavigationItem>
      </Nav>
    </>
  );
}

export default SidebarMenuItems;

