import axios from '../../config/axios.config';

import * as actionTypes from './actionTypes';

export const getQueueListStart = () => {
  return {
    type: actionTypes.QUEUELIST_GET_START
  };
};

export const getQueueListSuccess = queueList => {
  return {
    type: actionTypes.QUEUELIST_GET_SUCCESS,
    queueList: queueList
  };
};

export const getQueueListFail = error => {
  return {
    type: actionTypes.QUEUELIST_GET_FAIL,
    error: error
  };
};

export const getQueueList = () => {

  return dispatch => {
    dispatch(getQueueListStart());

    axios.get('/portal/queue')
      .then(response => {
        dispatch(getQueueListSuccess(response.data.resources));
      })
      .catch(error => {
        dispatch(getQueueListFail(error.response));
      });
  };
};
