import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/store/utility';

const initialState = {
  error: null,
  loading: false,
  appointmentList: []
};

const appointmentListGetStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const appointmentListGetSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    appointmentList: action.appointmentList
  });
};

const appointmentListGetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch ( action.type ) {
    case actionTypes.APPOINTMENTLIST_GET_START: return appointmentListGetStart(state, action);
    case actionTypes.APPOINTMENTLIST_GET_SUCCESS: return appointmentListGetSuccess(state, action);
    case actionTypes.APPOINTMENTLIST_GET_FAIL: return appointmentListGetFail(state, action);
    default:
      return state;
  }
};

export default reducer;
