import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/store/utility';

const initialState = {
  error: null,
  loading: false,
  helpList: []
};

const helpListGetStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const helpListGetSuccess = (state, action) => {
  return updateObject( state, {
    error: null,
    loading: false,
    helpList: action.helpList
  } );
};

const helpListGetFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.HELPLIST_GET_START: return helpListGetStart(state, action);
    case actionTypes.HELPLIST_GET_SUCCESS: return helpListGetSuccess(state, action);
    case actionTypes.HELPLIST_GET_FAIL: return helpListGetFail(state, action);
    default:
      return state;
  }
};

export default reducer;