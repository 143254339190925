import axios from '../../config/axios.config';

import * as actionTypes from './actionTypes';

export const getNewsListStart = () => {
  return {
    type: actionTypes.NEWSLIST_GET_START
  };
};

export const getNewsListSuccess = newsArticles => {
  return {
    type: actionTypes.NEWSLIST_GET_SUCCESS,
    newsArticles: newsArticles
  };
};

export const getNewsListFail = error => {
  return {
    type: actionTypes.NEWSLIST_GET_FAIL,
    error: error
  };
};

export const getNewsList = () => {

  return dispatch => {
    dispatch(getNewsListStart());

    axios.get('/portal/feed')
        .then(response => {
            dispatch(getNewsListSuccess(response.data.resources));
        })
        .catch(error => {
            dispatch(getNewsListFail(error.response));
        });
  };
};
