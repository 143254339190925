
const en = {
  // Common
  "Close": "Close",

  // Error modal.
  "Error": "Error",

  // Register successful
  "Back to login page": "Back to login page",
  "Registration successful": "Registration successful.",
  "Registration sent": "Your registration is sent for review. You will receive an update via email when it has been accepted.",

  // Pdf viewer modal.
  "View": "View",

  // Auth refresh modal.
  "Extend your session": "Extend your session?",
  "Would you like to extend your session": "Would you like to extend your session?",
  "Extend": "Extend",
  "Do not extend": "Do not extend",

}

export default en;
