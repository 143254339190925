import React from 'react';
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import Navbar from 'react-bootstrap/Navbar';

import Logo from '../../Logo/Logo';
import UserNavigationItems from '../UserNavigation/UserNavigationItems';
//import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

import './toolbar.scss';

const toggleClasses = ["sidebar-nav-toggle", "navbar-toggler"];

const Toolbar = props => {
  return (
    <div className="toolbar">
      <Navbar expand="lg" variant="dark" bg="dark">
        <Navbar.Brand href="#home">
          <Logo logo={props.logo}/>
        </Navbar.Brand>

        <div className="toolbar-navs ml-auto">
          <UserNavigationItems isAuthenticated={props.isAuth}/>
          {/*<LanguageSwitcher currentLanguage={props.currentLanguage}/>*/}
        </div>

        <button className={toggleClasses.join(' ')} type="button" onClick={props.toggleSidebarMenu} aria-controls="basic-navbar-nav" aria-expanded={props.isOpen}>
          <span className="navbar-toggler-icon"></span>
        </button>
      </Navbar>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    logo: state.app.logo,
  }
}

Toolbar.propTypes = {
  logo: PropTypes.string,
  isOpen: PropTypes.bool,
  isAuth: PropTypes.bool,
  toggleSidebarMenu: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(Toolbar);
