import axios from '../../config/axios.config';
import sociopackConfig from "../../config/sociopack.config";

import * as actionTypes from './actionTypes';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, expirationDate) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
    expirationDate: expirationDate
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('userId');
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

let checkAuthSetTimeout;
export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    checkAuthSetTimeout = setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const clearAuthTimeout = () => {
  if (checkAuthSetTimeout !== null) {
    clearTimeout(checkAuthSetTimeout);
  }
}

export const authRefreshStart = () => {
  return {
    type: actionTypes.AUTH_REFRESH_START
  };
};

export const authRefreshSuccess = (token, expirationDate) => {
  return {
    type: actionTypes.AUTH_REFRESH_SUCCESS,
    token: token,
    expirationDate: expirationDate
  };
};

export const authRefreshCancel = () => {
  return {
    type: actionTypes.AUTH_REFRESH_CANCEL
  };
};

export const authRefreshFail = error => {
  return {
    type: actionTypes.AUTH_REFRESH_FAIL,
    error: error,
  };
};


let checkAuthRefreshSetTimeout;
export const authRefreshTimeout = expirationTime => {
  return dispatch => {
    checkAuthRefreshSetTimeout = setTimeout(() => {
      dispatch(authRefreshStart());
    }, expirationTime * 1000);
  };
};

export const clearAuthRefreshTimeout = () => {
  if (checkAuthRefreshSetTimeout !== null) {
    clearTimeout(checkAuthRefreshSetTimeout);
  }
}

export const auth = (social_security, password) => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      nationalRegistryNumber: social_security.replace(/-/g, ""),
      password: password,
    };

    axios.post('/portal/authenticate', authData)
      .then(response => {
        const expirationDateTime = new Date(response.data.validTill);
        const authValidTimeInSeconds = (new Date(response.data.validTill).getTime() - new Date().getTime()) / 1000;
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('expirationDate', expirationDateTime);
        dispatch(authSuccess(response.data.token, expirationDateTime));
        dispatch(checkAuthTimeout(authValidTimeInSeconds));
        dispatch(authRefreshTimeout(authValidTimeInSeconds - sociopackConfig.app.authRefreshReminderTime));
        // Only for testing the authRefresh functionality.
        // dispatch(checkAuthTimeout(30));
        // dispatch(authRefreshTimeout(15));
      })
      .catch(error => {
        dispatch(authFail(error.response));
      });
  };
};

export const authRefresh = () => {
  return dispatch => {

    axios.get('/portal/authenticate')
      .then(response => {
        const expirationDateTime = new Date(response.data.validTill);
        const authValidTimeInSeconds = (new Date(response.data.validTill).getTime() - new Date().getTime()) / 1000;
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('expirationDate', expirationDateTime);

        // clear the previously set checkAuthTimeout en checkAuthRefreshTimeout
        clearAuthTimeout();
        clearAuthRefreshTimeout();

        // set new checkAuthTimeout and checkAuthRefreshTimeout
        dispatch(checkAuthTimeout(authValidTimeInSeconds));
        dispatch(authRefreshTimeout(authValidTimeInSeconds - sociopackConfig.app.authRefreshReminderTime));
        // Only for testing the authRefresh functionality.
        // dispatch(checkAuthTimeout(30));
        // dispatch(authRefreshTimeout(15));

        // set auth refresh as successful
        dispatch(authRefreshSuccess(response.data.token, expirationDateTime));
      })
      .catch(error => {
        dispatch(authRefreshFail(error.response));
      });
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  };
};

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expirationDate');
    const authValidTimeInSeconds = (new Date(expirationDate).getTime() - new Date().getTime()) / 1000;
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDateTime = new Date(expirationDate);
      if (expirationDateTime <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token, expirationDateTime));
        dispatch(checkAuthTimeout((expirationDateTime.getTime() - new Date().getTime()) / 1000 ));
        dispatch(authRefreshTimeout(authValidTimeInSeconds - sociopackConfig.app.authRefreshReminderTime));
        // Only for testing the authRefresh functionality.
        // dispatch(checkAuthTimeout(30));
        // dispatch(authRefreshTimeout(15));
      }
    }
  };
};

export const resetPasswordStart = () => {
  return {
    type: actionTypes.AUTH_RESET_PASS_START
  };
};

export const resetPasswordSuccess = () => {
  return {
    type: actionTypes.AUTH_RESET_PASS_SUCCESS,
  };
};

export const resetPasswordFail = (error) => {
  return {
    type: actionTypes.AUTH_RESET_PASS_FAIL,
    error: error
  };
};

export const resetPassword = (social_security, email) => {
  return dispatch => {
    dispatch(resetPasswordStart());
    const authResetData = {
      nationalRegistryNumber: social_security,
      email: email,
    };

    let url = '/portal/reset';
    axios.post(url, authResetData)
      .then(response => {
        dispatch(resetPasswordSuccess());
      })
      .catch(error => {
        dispatch(resetPasswordFail(error.response));
      });
  };
}

export const resetPasswordReset = () => {
  return {
    type: actionTypes.AUTH_RESET_PASS_RESET,
  };
}
