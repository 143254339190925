import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Link, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Helmet} from "react-helmet";
import Button from 'react-bootstrap/Button';
import Alert from "react-bootstrap/Alert";
import Form from 'react-bootstrap/Form';
import Badge from "react-bootstrap/Badge";

import * as actions from '../../store/actions';
import sociopackConfig from "../../config/sociopack.config";
import Spinner from '../../components/UI/Spinner/Spinner';
import {buildForm, checkFormValidity, getFormErrorMessages} from '../../services/form/utility';
import Breadcrumbs from "../../components/Navigation/Breadcrumb/Breadcrumb";
import ErrorMessage from "../../components/UI/ErrorMessage/ErrorMessage";

import "./dynForm.scoped.scss";

const DynForm = props => {
  const { t } = useTranslation('forms');
  const { formCategory, formId } = useParams();
  const {onGetDynForm, dynForm, dynFormElements, onSubmit} = props;
  const [requestForm, setRequestForm] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  useEffect(() => {
    onGetDynForm(formId);
  }, [onGetDynForm, formId]);

  useEffect(() => {
    setRequestForm(dynFormElements);
  }, [setRequestForm, dynFormElements]);

  const submitHandler = event => {
    event.preventDefault();
    let formIsValid = checkFormValidity(requestForm);

    if (formIsValid === true) {
      const dynFormByCategory = props.dynFormsByCategory[formCategory].forms[formId];
      const submittingForm = dynFormByCategory.form = requestForm
      onSubmit(submittingForm);
    } else {
      const errors = getFormErrorMessages(requestForm);
      setFormErrors(errors)
    }
  }

  let form = <Spinner />;

  let errorMessage = null;
  if (props.error) {
    errorMessage = <ErrorMessage errorMsg={props.errorMessage} />;
  }

  if (!props.loading) {
    form = buildForm(requestForm, setRequestForm);
    form = (
      <Form onSubmit={submitHandler}>
        {form}
        <div className="form-actions d-flex justify-content-end">
          <Button onClick={submitHandler}>{t('Submit')}</Button>
        </div>
      </Form>
    );
  }

  if (props.submitting) {
    form = <Spinner />;
  }

  let submittedMsg;
  if (props.submitted) {
    form = null;
    submittedMsg = (
      <Alert variant="success">
        {t('Request form sent')} &nbsp;
        {t('Request updates')}
        <Alert.Link as={Link} to={t('routes:/my-requests')}>
          {t('Go to requests')}
        </Alert.Link>
      </Alert>
    );
  }

  let formErrorMsgs = null;
  if (formErrors !== undefined && formErrors.length !== 0) {
    formErrorMsgs =
      (
        <div className="form-errors">
          <Alert variant="danger">
            <ul className="m-0">
              {formErrors.map((value, index) => {
                return (
                  <li key={index}>
                    {value}
                  </li>
                )
              })}
            </ul>
          </Alert>
        </div>
      );
  }

  let helmetData;
  let titleAndDescription;
  let breadcrumbsConfig;
  if (dynForm != null) {
    breadcrumbsConfig = [
      {to: t('routes:/'), linkText: t('Home')},
      {to: t('routes:/add'), linkText: t('pages:Request forms')},
      {active: true, linkText: props.dynForm.name},
    ];
    helmetData = (
      <Helmet>
        <title>{dynForm.name}{sociopackConfig.app.titleSuffix}</title>
        <link rel="canonical" href={sociopackConfig.app.baseURL + `${t("routes:/add")}/${formCategory}/${formId}`} />
        <meta name="description" content={dynForm.description} />
      </Helmet>
    );
    titleAndDescription = (
      <>
        <h1>{dynForm.name} <Badge variant="secondary">{dynForm.category}</Badge></h1>
        <Breadcrumbs config={breadcrumbsConfig} />
        <p>{dynForm.description}</p>
      </>
    );
  }

  return (
    <div className="dynForm">
      {helmetData}
      {titleAndDescription}
      {formErrorMsgs}
      {errorMessage}
      {submittedMsg}
      {form}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.dynForms.loading,
    dynForm: state.dynForms.currentForm,
    error: state.dynForms.error !== null,
    errorMessage: (state.dynForms.error !== null) ? state.dynForms.error.data : null,
    dynFormElements: state.dynForms.currentFormElements,
    dynFormsByCategory: state.dynForms.dynFormsByCategory,
    submitting: state.dynForms.submitting,
    submitted: state.dynForms.submitted,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetDynForm: (formId) => dispatch(actions.getDynForm(formId)),
    onSubmit: form => dispatch(actions.submitDynForm(form)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynForm);
