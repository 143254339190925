import React, {useEffect} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Helmet from "react-helmet";

import * as actions from "../../store/actions";
import sociopackConfig from "../../config/sociopack.config";
import Breadcrumbs from "../../components/Navigation/Breadcrumb/Breadcrumb";
import ErrorMessage from "../../components/UI/ErrorMessage/ErrorMessage";

import "./familycomposition.scss";
import Spinner from "../../components/UI/Spinner/Spinner";

const FamilyComposition = props => {
  const {onGetFamilyComp, familyList} = props;
  const {t} = useTranslation('pages');

  useEffect(() => {
    onGetFamilyComp();
  }, [onGetFamilyComp]);

  const changeRequest = (categoryName, formId) => {
    const categoryKey = categoryName.replace(/\s+/g, '').toLowerCase();
    props.history.push(`${t('routes:/add')}/${categoryKey}/${formId}`);
  }

  let familyComp = <Spinner />

  let errorMessage = null;
  if (props.error) {
    errorMessage = <ErrorMessage errorMsg={props.errorMessage} />;
  }

  if (!props.loading) {
    familyComp = familyList.map((family, index) => {
      return (
        <div key={index} className="family">
          <div className="familyInfo">
            <h2>{family.reference}</h2>

            <Row className="info-row" noGutters={true}>
              <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                <span className="label">{t("Family name")}</span>
              </Col>
              <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                <span className="value">{family.reference}</span>
              </Col>
            </Row>

            <Row className="info-row" noGutters={true}>
              <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                <span className="label">{t("Income")}</span>
              </Col>
              <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                <span className="value">{family.income}</span>
              </Col>
            </Row>

            <Row className="info-row" noGutters={true}>
              <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                <span className="label">{t("Income prerequisite")}</span>
              </Col>
              <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                <span className="value">{family.incomePrerequisite}</span>
              </Col>
            </Row>

            <Row className="info-row" noGutters={true}>
              <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                <span className="label">{t("Maximum income prerequisite")}</span>
              </Col>
              <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                <span className="value">{family.maxIncomePrerequisite}</span>
              </Col>
            </Row>
          </div>

          <Accordion>
            {family.people.map((member, index) => {
              const dateOfBirth = new Date(member.dateOfBirth);
              const dateOfBirthFormatted = dateOfBirth.getDate() + '-' + (dateOfBirth.getMonth()+1) + '-' + dateOfBirth.getFullYear();

              let rowFields =
                <>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey={index.toString()}>
                        {member.name} {member.activeUser ? <Badge variant="secondary">{t("You")}</Badge> : null}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index.toString()}>
                      <Card.Body>
                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Name")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <span className="value">{member.name}</span>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Gender")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <span className="value">{props.enums[member.gender.key][member.gender.value].code}</span>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Social security number")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <span className="value">{member.nationalRegistryNumber}</span>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Birth date")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <span className="value">{dateOfBirthFormatted}</span>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Birth place")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <span className="value">{member.placeOfBirth}</span>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Nationality")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <span className="value">{member.nationality}</span>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Address")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <span className="value">{member.address}</span>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Civil state")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <span className="value">{member.civilState}</span>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Co parenting")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <span className="value">{member.coParenting}</span>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Occupation")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <span className="value">{member.occupation}</span>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Relation")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <span className="value">{member.relation}</span>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Visitation rights")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <span className="value">{member.visitationRights}</span>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Email addresses")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <div className="values">
                              {member.emailAddresses.map((email, index) => {
                                return (
                                  <div key={index}>
                                    <span className="description">{email.description}</span>
                                    <span className="email">{email.email}</span>
                                    <span className="note">{email.note}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Income")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <div className="values">
                              {member.income.map((inc, index) => {
                                return (
                                  <div key={index}>
                                    {/* <span className="year">{inc.year}: </span> */}
                                    <span className="amount">{inc.amount}</span>
                                    <span className="note">{inc.note}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Phone numbers")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <div className="values">
                              {member.phoneNumbers.map((number, index) => {
                                return (
                                  <div key={index}>
                                    <span className="description">{number.description}</span>
                                    <span className="number">{number.number}</span>
                                    <span className="note">{number.note}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </Col>
                        </Row>

                        <Row className="info-row" noGutters={true}>
                          <Col xs={12} sm={5} md={4} lg={3} className="info-lbl">
                            <span className="label">{t("Note")}</span>
                          </Col>
                          <Col xs={12} sm={7} md={8} lg={9} className="info-val">
                            <span className="value">{member.note}</span>
                          </Col>
                        </Row>
                        {/* <div className="form-actions">
                          <ButtonGroup>
                            <Button onClick={() => changeRequest(member.editForm.category, member.editForm.id)}>{t('Change family member')}</Button>
                            <Button variant="danger" onClick={() => changeRequest(member.removeForm.category, member.removeForm.id)}>{t('Remove family member')}</Button>
                          </ButtonGroup>
                        </div> */}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </>;

              return (
                <div key={index} data-member-id={member.id}>
                  {rowFields}
                </div>
              );
            })}
          </Accordion>
          {/* <div className="form-actions">
            <Button variant="secondary" onClick={() => changeRequest(family.form.category, family.form.id)}>{t('Add family member')}</Button>
          </div> */}
        </div>
      );
    })
  }

  const breadcrumbsConfig = [
    {to: t('routes:/'), linkText: t('Home')},
    {active: true, linkText: t('My family')},
  ];

  return (
    <div className="families">
      <Helmet>
        <title>{t('Family composition')}{sociopackConfig.app.titleSuffix}</title>
        <link rel="canonical" href={sociopackConfig.app.baseURL + t('routes:/my-family')} />
        <meta name="description" content="Family composition" />
      </Helmet>
      <h1>{t("Family composition")}</h1>
      <Breadcrumbs config={breadcrumbsConfig} />
      {errorMessage}
      {familyComp}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    familyList: state.familyComp.family,
    loading: state.familyComp.loading,
    error: state.familyComp.error !== null,
    errorMessage: (state.familyComp.error !== null) ? state.familyComp.error.data : null,
    enums: state.app.enums,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetFamilyComp: () => dispatch(actions.getFamilyComp()),
  };
};

FamilyComposition.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  familyList: PropTypes.array,
  onGetFamilyComp: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FamilyComposition);
