import axios from '../../config/axios.config';

import * as actionTypes from './actionTypes';

export const getDynFormListStart = () => {
  return {
    type: actionTypes.DYN_FORMLIST_GET_START
  };
};

export const getDynFormListSuccess = dynFormList => {
  return {
    type: actionTypes.DYN_FORMLIST_GET_SUCCESS,
    dynFormList: dynFormList
  };
};

export const getDynFormListFail = (error) => {
  return {
    type: actionTypes.DYN_FORMLIST_GET_FAIL,
    error: error
  };
};

export const getDynFormStart = () => {
  return {
    type: actionTypes.DYN_FORM_GET_START
  };
};

export const getDynFormSuccess = dynForm => {
  return {
    type: actionTypes.DYN_FORM_GET_SUCCESS,
    currentForm: dynForm,
    currentFormElements: dynForm.form,
  };
}

export const getDynFormFail = (error) => {
  return {
    type: actionTypes.DYN_FORM_GET_FAIL,
    error: error
  };
};

export const getDynFormList = () => {

  return dispatch => {
    dispatch(getDynFormListStart());

    axios.get('/portal/forms')
      .then(response => {
        dispatch(getDynFormListSuccess(response.data.resources));
      })
      .catch(error => {
        dispatch(getDynFormListFail(error.response));
      });
  };
};

export const getDynForm = formId => {

  return dispatch => {
    dispatch(getDynFormStart());

    axios.get(`/portal/forms/${formId}`)
      .then(response => {
        dispatch(getDynFormSuccess(response.data));
      })
      .catch(error => {
        dispatch(getDynFormFail(error.response));
      });
  };
};


export const submitDynFormStart = () => {
  return {
    type: actionTypes.DYN_FORM_SUBMIT_START,
  };
}

export const submitDynFormSuccess = () => {
  return {
    type: actionTypes.DYN_FORM_SUBMIT_SUCCESS,
  };
}

export const submitDynFormFail = error => {
  return {
    type: actionTypes.DYN_FORM_SUBMIT_FAIL,
    error: error,
  };
}

export const submitDynForm = (form) => {
  return dispatch => {
    dispatch(submitDynFormStart());

    axios.post('/portal/forms', form)
      .then(response => {
        dispatch(submitDynFormSuccess());
      })
      .catch(error => {
        dispatch(submitDynFormFail(error.response));
      });
  };
};
