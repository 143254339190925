/**
 * All page translations.
 */
const pagesEn = {
  // Home
  "Dashboard": "Dashboard",
  "My information": "My Profile",
  "Overview of your profile information": "Overview of your profile information",
  "Go to your profile": "Go to your profile",
  "My family": "My family",
  "Information about your family": "Information about your family",
  "Go to your family information": "Go to your family information",
  "My queue": "My queue",
  "Information about your position in the queue.": "Information about your position in the queue.",
  "Go to your queue": "Go to your queue",
  "My requests": "My requests",
  "Overview of your requests": "Overview of your requests",
  "Go to your requests": "Go to your requests",
  "My documents": "My documents",
  "Overview of your documents": "Overview of your documents",
  "Go to your documents": "Go to your documents",
  "My invoices": "My invoices",
  "Overview of your invoices": "Overview of your invoices",
  "Go to your invoices": "Go to your invoices",
  "My appointments": "My appointments",
  "Overview of your appointments": "Overview of your appointments",
  "Go to your appointments": "Go to your appointments",

  // Notifications
  "Notifications": "Notifications",
  "No notifications for you yet": "No notifications for you yet.",
  "Go to invoices": "Go to invoices",
  "Go to documents": "Go to documents",
  "Read": "Read",
  "Sent": "Sent",
  "New": "New",

  // Profile && Family composition
  "Profile": "Profile",
  "Name": "Name",
  "Gender": "Gender",
  "Social security number": "Social security number",
  "Birth date": "Birth date",
  "Birth place": "Birth place",
  "Nationality": "Nationality",
  "Address": "Address",
  "Civil state": "Civil state",
  "Co parenting": "Co parenting",
  "Occupation": "Occupation",
  "Relation": "Relation",
  "Visitation rights": "Visitation rights",
  "Email addresses": "Email addresses",
  "Income": "Income",
  "Phone numbers": "Phone numbers",
  "Note": "Note",
  "Change profile data": "Change profile data",
  "Add family member": "Add family member",
  "Remove family member": "Remove family member",
  "Change family member": "Change family member",

  // Family composition
  "Family composition": "",
  "Family name": "",
  "Income prerequisite": "",
  "Maximum income prerequisite": "",
  "You": "",

  // Queue
  "Queue": "Queue",
  "No queue yet": "No queue yet.",
  "Position": "Position",
  "Registration Date": "Registration Date",
  "Registration N°": "Registration N°",
  "Status": "Status",

  // Requests
  "Requests": "Requests",
  "No requests yet": "No requests yet.",

  // Documents
  "Documents": "Documents",
  "No documents for you yet": "No documents for you yet",
  "Document date": "Document date",
  "Download": "Download",
  "Date": "Date",
  "Operations": "Operations",
  "Description": "Description",

  // Invoices
  "Invoices": "Invoices",
  "No invoices for you yet": "No invoices for you yet.",
  "Amount": "Amount",
  "Pending": "Pending",
  "Due date": "Due date",

  // Appointments
  "Appointments": "Appointments",
  "No appointments for you yet": "No appointments for you yet.",

  // News
  "News": "News",
  "No news for you yet": "No news for you yet.",

  // DynForms
  "Request forms": "Request forms",
  "No forms for you yet": "No forms for you yet",

}

export default pagesEn;
