export const INIT_APP_START = 'INIT_APP_START';
export const INIT_APP_SUCCESS = 'INIT_APP_SUCCESS';
export const INIT_APP_FAIL = 'INIT_APP_FAIL';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REFRESH_START = 'AUTH_REFRESH_START';
export const AUTH_REFRESH_SUCCESS = 'AUTH_REFRESH_SUCCESS';
export const AUTH_REFRESH_CANCEL = 'AUTH_REFRESH_CANCEL';
export const AUTH_REFRESH_FAIL = 'AUTH_REFRESH_FAIL';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const AUTH_RESET_PASS_START = 'AUTH_RESET_PASS_START';
export const AUTH_RESET_PASS_SUCCESS = 'AUTH_RESET_PASS_SUCCESS';
export const AUTH_RESET_PASS_FAIL = 'AUTH_RESET_PASS_FAIL';
export const AUTH_RESET_PASS_RESET = 'AUTH_RESET_PASS_RESET';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_RESET = 'REGISTER_RESET';
export const SET_REGISTER_REDIRECT_PATH = 'SET_REGISTER_REDIRECT_PATH';

export const NOTIFICATIONLIST_GET_START = 'NOTIFICATIONLIST_GET_START';
export const NOTIFICATIONLIST_GET_SUCCESS = 'NOTIFICATIONLIST_GET_SUCCESS';
export const NOTIFICATIONLIST_GET_FAIL = 'NOTIFICATIONLIST_GET_FAIL';

export const HELPLIST_GET_START = 'HELPLIST_GET_START';
export const HELPLIST_GET_SUCCESS = 'HELPLIST_GET_SUCCESS';
export const HELPLIST_GET_FAIL = 'HELPLIST_GET_FAIL';

export const NEWSLIST_GET_START = 'NEWSLIST_GET_START';
export const NEWSLIST_GET_SUCCESS = 'NEWSLIST_GET_SUCCESS';
export const NEWSLIST_GET_FAIL = 'NEWSLIST_GET_FAIL';

export const FAMILY_COMP_START = 'FAMILY_COMP_START';
export const FAMILY_COMP_SUCCESS = 'FAMILY_COMP_SUCCESS';
export const FAMILY_COMP_FAIL = 'FAMILY_COMP_FAIL';

export const DOCUMENTLIST_GET_START = 'DOCUMENTLIST_GET_START';
export const DOCUMENTLIST_GET_SUCCESS = 'DOCUMENTLIST_GET_SUCCESS';
export const DOCUMENTLIST_GET_FAIL = 'DOCUMENTLIST_GET_FAIL';
export const DOCUMENT_GET_START = 'DOCUMENT_GET_START'
export const DOCUMENT_GET_SUCCESS = 'DOCUMENT_GET_SUCCESS'
export const DOCUMENT_GET_FAIL = 'DOCUMENT_GET_FAIL'

export const INVOICELIST_GET_START = 'INVOICELIST_GET_START';
export const INVOICELIST_GET_SUCCESS = 'INVOICELIST_GET_SUCCESS';
export const INVOICELIST_GET_FAIL = 'INVOICELIST_GET_FAIL';
export const INVOICE_GET_START = 'INVOICE_GET_START'
export const INVOICE_GET_SUCCESS = 'INVOICE_GET_SUCCESS'
export const INVOICE_GET_FAIL = 'INVOICE_GET_FAIL'

export const ATTACHMENT_GET_START = 'ATTACHMENT_GET_START';
export const ATTACHMENT_GET_SUCCESS = 'ATTACHMENT_GET_SUCCESS';
export const ATTACHMENT_GET_FAIL = 'ATTACHMENT_GET_FAIL';

export const DYN_FORMLIST_GET_START = 'DYN_FORMLIST_GET_START';
export const DYN_FORMLIST_GET_SUCCESS = 'DYN_FORMLIST_GET_SUCCESS';
export const DYN_FORMLIST_GET_FAIL = 'DYN_FORMLIST_GET_FAIL';
export const DYN_FORM_GET_START = 'DYN_FORM_GET_START';
export const DYN_FORM_GET_SUCCESS = 'DYN_FORM_GET_SUCCESS';
export const DYN_FORM_GET_FAIL = 'DYN_FORM_GET_FAIL';
export const DYN_FORM_SUBMIT_START = 'DYN_FORM_SUBMIT_START';
export const DYN_FORM_SUBMIT_SUCCESS = 'DYN_FORM_SUBMIT_SUCCESS';
export const DYN_FORM_SUBMIT_FAIL = 'DYN_FORM_SUBMIT_FAIL';

export const PROFILE_GET_START = 'PROFILE_GET_START';
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS';
export const PROFILE_GET_FAIL = 'PROFILE_GET_FAIL';

export const QUEUELIST_GET_START = 'QUEUELIST_GET_START';
export const QUEUELIST_GET_SUCCESS = 'QUEUELIST_GET_SUCCESS';
export const QUEUELIST_GET_FAIL = 'QUEUELIST_GET_FAIL';

export const REQUESTLIST_GET_START = 'REQUESTLIST_GET_START';
export const REQUESTLIST_GET_SUCCESS = 'REQUESTLIST_GET_SUCCESS';
export const REQUESTLIST_GET_FAIL = 'REQUESTLIST_GET_FAIL';

export const APPOINTMENTLIST_GET_START = 'APPOINTMENTLIST_GET_START';
export const APPOINTMENTLIST_GET_SUCCESS = 'APPOINTMENTLIST_GET_SUCCESS';
export const APPOINTMENTLIST_GET_FAIL = 'APPOINTMENTLIST_GET_FAIL';
