import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/store/utility';

const initialState = {
  error: null,
  loading: false,
  attachments: {},
};

const attachmentGetStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const attachmentGetSuccess = (state, action) => {
  let attachments;
  if (!state.attachments.hasOwnProperty(action.attachmentType)) {
    attachments = state.attachments;
    attachments[action.attachmentType] = {};
  }

  const docAttachments = updateObject(state.attachments[action.attachmentType], {[action.attachment.document.legacyId]: action.attachment});
  attachments = updateObject(state.attachments, {[action.attachmentType]: docAttachments})

  return updateObject( state, {
    error: null,
    loading: false,
    attachments: attachments
  });
};

const attachmentGetFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.ATTACHMENT_GET_START: return attachmentGetStart(state, action);
    case actionTypes.ATTACHMENT_GET_SUCCESS: return attachmentGetSuccess(state, action);
    case actionTypes.ATTACHMENT_GET_FAIL: return attachmentGetFail(state, action);
    default:
      return state;
  }
};

export default reducer;
