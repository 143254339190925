import React from 'react';
import PropTypes from "prop-types";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import './formElement.scoped.scss';

const formElement = props => {
  let inputElement;
  const inputClasses = ["inputElement"];

  if (props.invalid && Object.keys(props.validation).length >= 0  && props.touched) {
    inputClasses.push("is-invalid");
  }
  else if (!props.invalid && Object.keys(props.validation).length >= 0 && props.touched) {
    inputClasses.push("is-valid");
  }

  const updateFormElementConfigKey = (key, value) => {
    let updatedFormElement;
    updatedFormElement = {
      ...props.formElementConfig,
      elementConfig: {
        ...props.elementConfig,
        [key]: value,
      }
    }
    return updatedFormElement;
  }

  const passwordToggle = () => {
    if (props.elementType === 'password') {
      let updatedFormElement;
      if (props.elementConfig.type === 'password') {
        updatedFormElement = updateFormElementConfigKey('type', 'text');
      }
      else if (props.elementConfig.type === 'text') {
        updatedFormElement = updateFormElementConfigKey('type', 'password');
      }
      if (updatedFormElement !== null) {
        props.updateFormConfig(props.elementId, updatedFormElement);
      }
    }
  }

  switch ( props.elementType ) {
    case ( 'password' ):
      let toggleClass = props.elementConfig.type === 'text' ? 'show' : 'hide';
      let toggleLabel = props.elementConfig.type === 'text' ?
        props.formElementConfig.toggleLabelVisible :
        props.formElementConfig.toggleLabelHidden;
      let classes = ['input-group-text','nav-iconized', toggleClass]
      inputElement = (
        <InputGroup>
          <Form.Control
          className={inputClasses.join(' ')}
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
          />
          <InputGroup.Append>
            <span onClick={passwordToggle} className={classes.join(' ')}>
              <i className="material-icons">remove_red_eye</i>
              <span>{toggleLabel}</span>
            </span>
          </InputGroup.Append>
        </InputGroup>

        );
      break;
    case ( 'input' ):
      inputElement = <Form.Control
        className={inputClasses.join(' ')}
        {...props.elementConfig}
        value={props.value}
        onChange={props.changed} />;
      break;
    case ( 'switch' ):
      inputElement = <Form.Check
        type="switch"
        className={inputClasses.join(' ')}
        {...props.elementConfig}
        value={props.value}
        onChange={props.changed} />;
      break;
    case ( 'checkbox' ):
      inputElement = <Form.Check
        className={inputClasses.join(' ')}
        {...props.elementConfig}
        value={props.value}
        onChange={props.changed} />;
      break;
    case ( 'textarea' ):
      inputElement = <Form.Control
        as="textarea"
        className={inputClasses.join(' ')}
        {...props.elementConfig}
        value={props.value}
        onChange={props.changed} />;
      break;
    case ( 'select' ):
      inputElement = (
        <Form.Control
          as="select"
          className={inputClasses.join(' ')}
          value={props.value}
          onChange={props.changed}
        >
          {props.elementConfig.options.map(option => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </Form.Control>
      );
      break;
    default:
      inputElement = <Form.Control
        className={inputClasses.join(' ')}
        {...props.elementConfig}
        value={props.value}
        onChange={props.changed} />;
  }

  let inputGroup = null;
  if (props.prefix) {
    inputGroup = (
      <InputGroup.Prepend>
        <InputGroup.Text id="inputGroupPrepend">{props.prefix}</InputGroup.Text>
      </InputGroup.Prepend>
    )
  }

  let label = null;
  if (props.label && props.label !== '') {
    label = <Form.Label className="label">{props.label}</Form.Label>
  }

  return (
    <Form.Group controlId={"form" + props.elementId} className="input">
      {label}
      <InputGroup>
        {inputGroup}
        {inputElement}
        <Form.Control.Feedback type={props.invalid ? "invalid" : "valid"}>
          {props.description}
        </Form.Control.Feedback>
      </InputGroup>
      {!props.touched ?
        <Form.Text muted>
          {props.description}
        </Form.Text>
        : null
      }
    </Form.Group>
  );
};

formElement.propTypes = {
  validation: PropTypes.object,
  invalid: PropTypes.bool,
  touched: PropTypes.bool,
  elementType: PropTypes.string,
  elementConfig: PropTypes.object,
  value: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  elementId: PropTypes.string,
  description: PropTypes.string,
  changed: PropTypes.func.isRequired,
};

export default formElement;
