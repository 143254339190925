import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet'
import {connect} from "react-redux";
import PropTypes from "prop-types";

import * as actions from "../../store/actions";
import sociopackConfig from "../../config/sociopack.config";
import RequestListItem from "./RequestListItem";
import Spinner from "../../components/UI/Spinner/Spinner";
import Breadcrumbs from "../../components/Navigation/Breadcrumb/Breadcrumb";
import ErrorMessage from "../../components/UI/ErrorMessage/ErrorMessage";

import './requestList.scss';

const RequestList = props => {
  const {t} = useTranslation('pages');
  const {onGetRequestList} = props;

  useEffect(() => {
    onGetRequestList();
  }, [onGetRequestList]);

  let requestList = <Spinner />;

  let errorMessage = null;
  if (props.error) {
    errorMessage = <ErrorMessage errorMsg={props.errorMessage}/>;
  }

  if (!props.loading) {
    requestList = <p>{t('No requests yet')}</p>
    if (props.requestList.length > 0) {
      requestList = props.requestList.map((request, index) => {
        return <RequestListItem
            key={request.id}
            index={index}
            name={request.name}
            subject={request.workflow}
            description={request.description}
            form={request.form}
            category={request.category}
            formTemplateId={request.formTemplateId}
        />
      });
    }
  }

  const breadcrumbsConfig = [
    {to: t('routes:/'), linkText: t('Home')},
    {active: true, linkText: t('My requests')},
  ];

  return (
      <>
        <Helmet>
          <title>{t('Requests')}{sociopackConfig.app.titleSuffix}</title>
          <link rel="canonical" href={sociopackConfig.app.baseURL + t('routes:/my-requests')} />
          <meta name="description" content={t('Requests')} />
        </Helmet>
        <h1>{t('Requests')}</h1>
        <Breadcrumbs config={breadcrumbsConfig} />
        {errorMessage}
        <div className="requestList">
          {requestList}
        </div>
      </>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.requests.loading,
    error: state.requests.error !== null,
    errorMessage: (state.requests.error !== null) ? state.requests.error.data : null,
    requestList: state.requests.requestList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetRequestList: () => dispatch( actions.getRequestList()),
  };
}

RequestList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  requestList: PropTypes.array,
  onGetRequestList: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestList);
