import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/store/utility';

const initialState = {
  error: null,
  loading: false,
  dynFormList: [],
  dynFormsByCategory: {},
  currentForm: null,
  currentFormElements: [],
  submitting: false,
  submitted: false,
};

const dynFormListGetStart = ( state, action ) => {
  return updateObject( state, {
    error: null,
    loading: true,
    submitting: false,
    submitted: false,
  });
};

const dynFormListGetSuccess = (state, action) => {

  let formsByCategory = {};
  action.dynFormList.forEach(dynForm => {
    const categoryKey = dynForm.category.replace(/\s+/g, '').toLowerCase();
    if (formsByCategory[categoryKey]) {
      formsByCategory = {
        ...formsByCategory,
        [categoryKey]: {
          ...formsByCategory[categoryKey],
          forms: {
            ...formsByCategory[categoryKey].forms,
            [dynForm.id]: [dynForm][0]
          }
        }
      };
    } else {
      formsByCategory = {
        ...formsByCategory,
        [categoryKey]: {
          name: dynForm.category,
          forms: {[dynForm.id]: [dynForm][0]}
        }
      };
    }
  });

  return updateObject( state, {
    error: null,
    loading: false,
    dynFormList: action.dynFormList,
    dynFormsByCategory: formsByCategory,
  });
};

const dynFormListGetFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const dynFormGetStart = ( state, action ) => {
  return updateObject( state, {
    error: null,
    loading: true,
    submitting: false,
    submitted: false,
  });
};

const dynFormGetSuccess = (state, action) => {
  return updateObject(state, {
    currentForm: action.currentForm,
    currentFormElements: action.currentFormElements,
    loading: false,
    error: null,
  });
}

const dynFormGetFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const dynFormSubmitStart = (state, action) => {
  return updateObject(state, {
    submitting: true,
    submitted: false,
    error: null,
  });
}

const dynFormSubmitSuccess = (state, action) => {
  return updateObject(state, {
    submitting: false,
    submitted: true,
    error: null,
  });
}

const dynFormSubmitFail = (state, action) => {
  return updateObject(state, {
    submitting: false,
    submitted: false,
    error: action.error,
  });
}

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.DYN_FORMLIST_GET_START: return dynFormListGetStart(state, action);
    case actionTypes.DYN_FORMLIST_GET_SUCCESS: return dynFormListGetSuccess(state, action);
    case actionTypes.DYN_FORMLIST_GET_FAIL: return dynFormListGetFail(state, action);
    case actionTypes.DYN_FORM_GET_START: return dynFormGetStart(state, action);
    case actionTypes.DYN_FORM_GET_SUCCESS: return dynFormGetSuccess(state, action);
    case actionTypes.DYN_FORM_GET_FAIL: return dynFormGetFail(state, action);
    case actionTypes.DYN_FORM_SUBMIT_START: return dynFormSubmitStart(state, action);
    case actionTypes.DYN_FORM_SUBMIT_SUCCESS: return dynFormSubmitSuccess(state, action);
    case actionTypes.DYN_FORM_SUBMIT_FAIL: return dynFormSubmitFail(state, action);
    default:
      return state;
  }
};

export default reducer;
