import axios from '../../config/axios.config';

import * as actionTypes from './actionTypes';

export const getProfileStart = () => {
  return {
    type: actionTypes.PROFILE_GET_START
  };
};

export const getProfileSuccess = profile => {
  return {
    type: actionTypes.PROFILE_GET_SUCCESS,
    profile: profile
  };
};

export const getProfileFail = error => {
  return {
    type: actionTypes.PROFILE_GET_FAIL,
    error: error
  };
};

export const getProfile = () => {
  return dispatch => {
    dispatch(getProfileStart());

    axios.get('/portal/profile')
      .then(response => {
        dispatch(getProfileSuccess(response.data));
      })
      .catch(error => {
        dispatch(getProfileFail(error.response));
      });
  };
};
