import React from 'react';

import './flexTblHead.scss';

const FlexTblHead = props => {

  return (
    <div className="listHeader">
      {props.children}
    </div>
  )
}

export default FlexTblHead;
