import React from "react";
import {useTranslation} from "react-i18next";
import Alert from "react-bootstrap/Alert";

const ErrorMessage = props => {
  const {t} = useTranslation('pages');

  return (
    <Alert variant="danger">{t("Error")}: {props.errorMsg}</Alert>
  );
}

export default ErrorMessage;
