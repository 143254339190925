import axios from '../../config/axios.config';

import * as actionTypes from './actionTypes';

export const getRequestListStart = () => {
  return {
    type: actionTypes.REQUESTLIST_GET_START
  };
};

export const getRequestListSuccess = requestList => {
  return {
    type: actionTypes.REQUESTLIST_GET_SUCCESS,
    requestList: requestList
  };
};

export const getRequestListFail = error => {
  return {
    type: actionTypes.REQUESTLIST_GET_FAIL,
    error: error
  };
};

export const getRequestList = () => {

  return dispatch => {
    dispatch(getRequestListStart());

    axios.get('/portal/requests')
      .then(response => {
        dispatch(getRequestListSuccess(response.data.resources));
      })
      .catch(error => {
        dispatch(getRequestListFail(error.response));
      });
  };
};
