import axios from '../../config/axios.config';

import * as actionTypes from './actionTypes';

export const getAttachmentStart = () => {
  return {
    type: actionTypes.ATTACHMENT_GET_START
  };
};

export const getAttachmentSuccess = (attachment, attachmentType) => {
  return {
    type: actionTypes.ATTACHMENT_GET_SUCCESS,
    attachment: attachment,
    attachmentType: attachmentType,
  };
};

export const getAttachmentFail = (error) => {
  return {
    type: actionTypes.ATTACHMENT_GET_FAIL,
    error: error
  };
};

export const getAttachment = (url, attachmentType) => {

  return dispatch => {
    dispatch(getAttachmentStart());
    axios.get(`/${url}`)
      .then(response => {
        dispatch(getAttachmentSuccess(response.data, attachmentType));
      })
      .catch(error => {
        dispatch(getAttachmentFail(error.response));
      });
  };
};
