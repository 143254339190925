import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import { useTranslation } from "react-i18next";
import { InView } from 'react-intersection-observer';
import { pdfjs } from 'react-pdf';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PropTypes from "prop-types";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// import './pdfViewerModal.scoped.scss';

const PdfViewerModal = props => {
  const { t } = useTranslation('modals');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const inViewHandler = (inView, entry) => {
    if (inView) {
      setPageNumber(parseInt(entry.target.firstChild.dataset.pageNumber))
    }
  }

  // Currently set to show al pages.
  // Alternatively we could be showing a single page with navigation:
  // https://github.com/wojtekmaj/react-pdf/wiki/Recipes

  return (
    <>
      <Button variant="secondary" onClick={handleShow} size="sm" className="nav-iconized no-lbl-icon" title={t("View")}>
        <i className="material-icons">visibility</i>
        <span>{t("View")}</span>
      </Button>
      <Modal size="lg" show={show} scrollable="true" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Document
            file={props.document}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode="svg"
          >
            {Array.from(
              new Array(numPages),
              (el, index) => (
                <InView
                  as="div"
                  key={`page_${index + 1}`}
                  onChange={(inView, entry) => inViewHandler(inView, entry)}>
                  <Page
                    pageNumber={index + 1}
                  />
                </InView>
              ),
            )}
          </Document>
        </Modal.Body>
        <Modal.Footer>
          <p>Page {pageNumber} of {numPages}</p>
          <Button variant="secondary" onClick={handleClose}>
            {t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

PdfViewerModal.propTypes = {
  title: PropTypes.string,
  document: PropTypes.string,
};

// use react memo with second argument to define when this component should be rerendered.
// It's the inverse of shouldComponentUpdate, so memo should be triggered when the props are equal.
export default React.memo(PdfViewerModal, (prevProps, nextProps) => {
  return nextProps.show === prevProps.show &&
    nextProps.handleClose === prevProps.handleClose &&
    nextProps.title === prevProps.title &&
    nextProps.document === prevProps.document
});
