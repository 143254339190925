import React from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import {Link} from "react-router-dom";

import * as actions from "../../store/actions";
import DateFormatted from "../../components/UI/DateFormatted/DateFormatted";

const NotificationListItem = props => {
  const {t} = useTranslation('pages');
  const {onReadNotification} = props;

  const notificationRead = id => {
    onReadNotification(id);
  }

  let status = 'light';
  let readDate;
  if (props.read == null) {
    status = 'primary';
  }

  if (props.read !== null) {
    readDate = (
      <div className="meta-item read-date">
        <span className="label">{t("Read")}:</span>
        <DateFormatted format="DD-MM-YYYY HH:mm" date={props.read} />
      </div>
    );
  }
  // else { // Temporary to test...
  //   readDate = (
  //     <div className="meta-item read-date">
  //       <span className="label">{t("Read")}:</span>
  //       <DateFormatted format="DD-MM-YYYY HH:mm" date={props.notified} />
  //     </div>
  //   );
  // }

  let linkToModule;
  switch (props.module) {
    case 'invoices':
      linkToModule = {to: t("routes:/my-invoices"), linkText: t("Go to invoices")}
      break;
    case 'documents':
      linkToModule = {to: t("routes:/my-documents"), linkText: t("Go to documents")}
      break;
    case 'profile':
      linkToModule = {to: t("routes:/my-information"), linkText: t("Go to profile")}
      break;
    case 'compositions':
      linkToModule = {to: t("routes:/my-family"), linkText: t("Go to family overview")}
      break;
    case 'queue':
      linkToModule = {to: t("routes:/my-queue"), linkText: t("Go to queue")}
      break;
    case 'Aanvraag':
      linkToModule = {to: t("routes:/my-requests"), linkText: t("Ga naar mijn aanvragen")}
      break;
    case 'Afspraak':
      linkToModule = {to: t("routes:/my-appointments"), linkText: t("Ga naar mijn afspraken")}
      break;
    case 'feed':
      linkToModule = {to: t("routes:/info"), linkText: t("Go to news")}
      break;
    case 'add':
      linkToModule = {to: t("routes:/add"), linkText: t("Go to add")}
      break;
    default:
      break;
  }

  let notification = (
    <>
      <h3 className="alert-title"><span>{props.content}</span>
      {/* <Badge variant={status}>{t("New")}</Badge> */}
      </h3>
      <div className="alert-body">{linkToModule.linkText}</div>
      <footer className="alert-meta">
        <div className="meta-item sent-date">
          <span className="label">{t("Sent")}:</span>
          <DateFormatted format="DD-MM-YYYY HH:mm" date={props.notified} />
        </div>
        {readDate}
      </footer>
    </>
  );

  if (linkToModule) {
    notification = (
      <Alert.Link as={Link} to={linkToModule.to}>
        {notification}
      </Alert.Link>
    );
  }
  else {
    notification = (
      <span className="alert-no-link">
        {notification}
      </span>
    );
  }

  return (
    <Alert className="notification-alert" variant={status} onClick={id => notificationRead(id)}>
      {notification}
    </Alert>
  );
}

const mapDispatchToProps = dispatch => {
  return {    
    onReadNotification: (id) => dispatch(actions.setNotificationToRead(id)),
  };
}

NotificationListItem.propTypes = {
  status: PropTypes.string,
  notified: PropTypes.string,
  read: PropTypes.string,
  module: PropTypes.string,
  content: PropTypes.string,
  referenceid: PropTypes.number,
}

export default connect(
  null,
  mapDispatchToProps
)(NotificationListItem);
