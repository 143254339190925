import React, {useState} from 'react';
import { connect } from 'react-redux';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import * as actions from "../../store/actions";
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SidebarMenu from "../../components/Navigation/SidebarMenu/SidebarMenu";
import Spinner from '../../components/UI/Spinner/Spinner';
import ConfirmModal from "../../components/UI/Modal/ConfirmModal";

import './layout.scoped.scss';

const headerClasses = ["main-header", "col-12"];
const sidebarClasses = ["main-sideBar", "col-12", "col-lg-2"];//"col-md-3",
const contentAuthClasses = ["main-content", "col-12", "col-lg-10", "offset-lg-2"];//"col-md-9", , "offset-md-3"
const contentNoAuthClasses = ["main-content", "col-12"];

const Layout = props => {
  const {t} = useTranslation();
  const [sidebarMenuOpen, setSidebarMenuOpen] = useState(false);
  const toggleSidebarMenu = () => setSidebarMenuOpen(!sidebarMenuOpen);

  let content = (
    <>
      <main className={contentNoAuthClasses.join(' ')}>
        <div className="main-content-inner h-100">
          <Spinner />
        </div>
      </main>
    </>
  );
  if (!props.isInitializing) {
    content = (
      <>
        <header className={headerClasses.join(' ')}>
          <Toolbar
            isAuth={props.isAuthenticated}
            languageChangedHandler={props.languageChangedHandler}
            toggleSidebarMenu={toggleSidebarMenu}
            isOpen={sidebarMenuOpen}
            // currentLanguage={props.currentLanguage}
          />
        </header>
        {
          props.isAuthenticated ?
          <aside className={sidebarClasses.join(' ')}>
            <SidebarMenu
              isOpen={sidebarMenuOpen}
            />
          </aside>
          : null
        }

        <main className={props.isAuthenticated ? contentAuthClasses.join(' ') : contentNoAuthClasses.join(' ')}>
          <div className="main-content-inner h-100">
            {props.children}
          </div>
        </main>
      </>
    );
  }

  const closeModal = () => {
    props.onAuthRefreshCancel();
  }

  const handleAuthRefresh = () => {
    props.onAuthRefresh();
  }

  let authRefreshModal;
  if (props.isAuthRefreshTime) {
    authRefreshModal = <ConfirmModal
      show={props.isAuthRefreshTime}
      size="md"
      showCloseButton
      handleClose={closeModal}
      title={t('modals:Extend your session')}
      closeText={t('modals:Do not extend')}
      confirmText={t('modals:Extend')}
      confirmColor="primary"
      confirmHandler={handleAuthRefresh}
    >
      <p>{t('modals:Would you like to extend your session')}</p>
    </ConfirmModal>
  }

  return (
    <Container fluid>
      <Row id="myGroup">
        {authRefreshModal}
        {content}
      </Row>
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    isAuthRefreshTime: state.auth.authRefreshTimeout,
    isInitializing: state.app.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuthRefresh: () => dispatch(actions.authRefresh()),
    onAuthRefreshCancel: () => dispatch(actions.authRefreshCancel())
  };
}

Layout.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAuthRefreshTime: PropTypes.bool,
  isInitializing: PropTypes.bool,
  onAuthRefresh: PropTypes.func.isRequired,
  onAuthRefreshCancel: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
