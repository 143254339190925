import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import forms from '../locales/forms'
import routes from '../locales/routes';
import pages from '../locales/pages';
import links_and_buttons from '../locales/links_and_buttons';
import modals from '../locales/modals';

const languages = ['en', 'nl'];
const resources = {
  en: {
    forms: forms.en,
    routes: routes.en,
    pages: pages.en,
    links: links_and_buttons.en,
    modals: modals.en,
  },
  nl: {
    forms: forms.nl,
    routes: routes.nl,
    pages: pages.nl,
    links: links_and_buttons.nl,
    modals: modals.nl,
  }
};

i18n
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    resources: resources,
    lng: 'nl',
    fallbackLng: 'nl',
    supportedLngs: [
      'en',
      'nl',
    ],
    preload: [
      'en',
      'nl',
    ],
    languages: [
      'en',
      'nl',
    ],
    cleanCode: true,
    ns: ["forms", "routes", "pages", "links"],
    otherLanguages: ['en'],
    // debug: true,
  });

i18n.languages = languages;


export default i18n;


