import axios from '../../config/axios.config';

import * as actionTypes from './actionTypes';

export const getAppointmentListStart = () => {
  return {
    type: actionTypes.APPOINTMENTLIST_GET_START
  };
};

export const getAppointmentListSuccess = appointmentList => {
  return {
    type: actionTypes.APPOINTMENTLIST_GET_SUCCESS,
    appointmentList: appointmentList
  };
};

export const getAppointmentListFail = error => {
  return {
    type: actionTypes.APPOINTMENTLIST_GET_FAIL,
    error: error
  };
};

export const getAppointmentList = () => {

  return dispatch => {
    dispatch(getAppointmentListStart());

    axios.get('/portal/appointments')
      .then(response => {
        dispatch(getAppointmentListSuccess(response.data.resources));
      })
      .catch(error => {
        dispatch(getAppointmentListFail(error.response));
      });
  };
};
