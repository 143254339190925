import React from "react";
import { pdfjs } from 'react-pdf';
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import PdfViewerModal from "../../components/UI/Modal/PdfViewerModal";
import DateFormatted from "../../components/UI/DateFormatted/DateFormatted";
import FlexTbl from "../../components/UI/FlexTable/FlexTbl";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const InvoiceItem = props => {
  const {t} = useTranslation('pages');

  let documentViewModal;
  if (props.type === "PDF") {
    documentViewModal =
      <PdfViewerModal
        title={props.name}
        document={props.document}
      />
  } else if (props.type === "image") {
    // @Todo: add image viewer modal instead of pdf viewer modal
  }

  return (
    <FlexTbl.Row>
      <FlexTbl.Data label={t(props.tblConfig.headers[0].name)} cls="data-col-1">{props.name}</FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[1].name)} cls="data-col-2">{props.amount}</FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[2].name)} cls="data-col-2">{props.pending}</FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[3].name)} cls="data-col-3"><DateFormatted format="DD-MM-YYYY" date={props.due} /></FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[4].name)} cls="data-col-4">{props.description}</FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[5].name)} cls="data-col-5">
        <ButtonGroup aria-label={t(props.tblConfig.headers[4].name)} className="inv-item-btns">
          <a className="btn btn-primary btn-sm nav-iconized no-lbl-icon" href={props.document} download={`${props.name}${props.extension}`} title={t("Download")}>
            <i className="material-icons">save_alt</i>
            <span>{t("Download")}</span>
          </a>
          {documentViewModal}
        </ButtonGroup>
      </FlexTbl.Data>
    </FlexTbl.Row>
  );
}

InvoiceItem.propTypes = {
  name: PropTypes.string,
  amount: PropTypes.string,
  due: PropTypes.string,
  description: PropTypes.string,
  extension: PropTypes.string,
  document: PropTypes.string,
};

export default InvoiceItem;
