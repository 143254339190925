import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import Helmet from "react-helmet";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import * as actions from "../../store/actions";
import sociopackConfig from "../../config/sociopack.config";
import Spinner from "../../components/UI/Spinner/Spinner";
import InvoiceItem from "./InvoiceItem";
import FlexTbl from "../../components/UI/FlexTable/FlexTbl";
import Breadcrumbs from "../../components/Navigation/Breadcrumb/Breadcrumb";
import ErrorMessage from "../../components/UI/ErrorMessage/ErrorMessage";

import "./invoiceList.scss";

const InvoiceList = props => {
  const { t } = useTranslation('pages');
  const {onGetInvoiceList} = props;

  useEffect(() => {
    onGetInvoiceList();
  }, [onGetInvoiceList]);

  let invoiceList = <Spinner />;

  let errorMessage = null;
  if (props.error) {
    errorMessage = <ErrorMessage errorMsg={props.errorMessage} />;
  }

  const tblConfig = {
    headers: [
      {name: 'Name', type: 'text'},
      {name: 'Amount', type: 'text'},
      {name: 'Pending', type: 'text'},
      {name: 'Due date', type: 'date'},
      {name: 'Description', type: 'text'},
      {name: 'Operations', type: 'text'},
    ]
  }

  if (!props.loading) {
    invoiceList = <p>{t('No invoices for you yet')}</p>
    if (props.invoices.length > 0) {
      invoiceList = props.invoices.map(invoice => {
        return <InvoiceItem
          key={invoice.id}
          due={invoice.due}
          invoiceId={invoice.invoiceId}
          amount={invoice.amount}
          pending={invoice.pending}
          document={invoice.document.data}
          name={invoice.document.name}
          description={invoice.document.description}
          type={props.enums[invoice.document.type.key][invoice.document.type.value].code}
          extension={invoice.document.extension}
          tblConfig={tblConfig}
        />
      });
    }
  }

  const breadcrumbsConfig = [
    {to: t('routes:/'), linkText: t('Home')},
    {active: true, linkText: t('My invoices')},
  ];

  return (
    <>
      <Helmet>
        <title>{t('My invoices')}{sociopackConfig.app.titleSuffix}</title>
        <link rel="canonical" href={sociopackConfig.app.baseURL + t('routes:/my-invoices')} />
        <meta name="description" content={t('My invoices')} />
      </Helmet>
      <h1>{t('Invoices')}</h1>
      <Breadcrumbs config={breadcrumbsConfig} />
      {errorMessage}
      <div className="invoiceList">
        <FlexTbl cls={`flex-cols-${tblConfig.headers.length}`}>
          <FlexTbl.Head>
            <FlexTbl.Row>
              {tblConfig.headers.map((header, index) => {
                return <FlexTbl.Data cls={`data-col-${index+1}`} key={index} label={t(header.name)}/>
              })}
            </FlexTbl.Row>
          </FlexTbl.Head>
          <FlexTbl.Body>
            {invoiceList}
          </FlexTbl.Body>
        </FlexTbl>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.invoices.loading,
    error: state.invoices.error !== null,
    errorMessage: (state.invoices.error !== null) ? state.invoices.error.data : null,
    invoices: state.invoices.invoiceList,
    enums: state.app.enums,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetInvoiceList: () => dispatch( actions.getInvoiceList() ),
  };
}

InvoiceList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  invoices: PropTypes.array,
  onGetInvoiceList: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceList);
