import axios from '../../config/axios.config';

import * as actionTypes from './actionTypes';

export const getFamilyCompStart = () => {
  return {
    type: actionTypes.FAMILY_COMP_START
  };
};

export const getFamilyCompSuccess = familyComp => {
  return {
    type: actionTypes.FAMILY_COMP_SUCCESS,
    family: familyComp
  };
};

export const getFamilyCompFail = error => {
  return {
    type: actionTypes.FAMILY_COMP_FAIL,
    error: error
  };
};

export const getFamilyComp = () => {
  return dispatch => {
    dispatch(getFamilyCompStart());

    axios.get('/portal/compositions')
      .then(response => {
        dispatch(getFamilyCompSuccess(response.data.resources));
      })
      .catch(error => {
        dispatch(getFamilyCompFail(error.response));
      });
  };
};
