import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/store/utility';

const initialState = {
  token: null,
  expirationDate: null,
  error: null,
  loading: false,
  authRedirectPath: '/',
  passwordResetSuccessful: null,
  authRefreshTimeout: false
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    expirationDate: action.expirationDate,
    error: null,
    loading: false,
    authRefreshTimeout: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    authRefreshTimeout: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    loading: false,
    expirationDate: null,
    authRefreshTimeout: false
  });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path })
}

const authResetPasswordStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authResetPasswordSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    passwordResetSuccessful: true
  });
};

const authResetPasswordFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false,
    passwordResetSuccessful: false
  });
};

const authResetPasswordReset = (state, action) => {
  return updateObject( state, {
    passwordResetSuccessful: null
  });
};

const authRefreshStart = (state, action) => {
  return updateObject(state, { authRefreshTimeout: true, loading: true });
};

const authRefreshSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    expirationDate: action.expirationDate,
    error: null,
    loading: false,
    authRefreshTimeout: false
  });
};

const authRefreshCancel = (state, action) => {
  return updateObject(state, { authRefreshTimeout: false });
};

const authRefreshFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    authRefreshTimeout: false
  });
};

const reducer = (state = initialState, action) => {
  switch ( action.type ) {
    case actionTypes.AUTH_START: return authStart(state, action);
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
    case actionTypes.AUTH_FAIL: return authFail(state, action);
    case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
    case actionTypes.AUTH_REFRESH_START: return authRefreshStart(state, action);
    case actionTypes.AUTH_REFRESH_SUCCESS: return authRefreshSuccess(state, action);
    case actionTypes.AUTH_REFRESH_CANCEL: return authRefreshCancel(state, action);
    case actionTypes.AUTH_REFRESH_FAIL: return authRefreshFail(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state,action);
    case actionTypes.AUTH_RESET_PASS_START: return authResetPasswordStart(state, action);
    case actionTypes.AUTH_RESET_PASS_SUCCESS: return authResetPasswordSuccess(state, action);
    case actionTypes.AUTH_RESET_PASS_FAIL: return authResetPasswordFail(state, action);
    case actionTypes.AUTH_RESET_PASS_RESET: return authResetPasswordReset(state, action);
    default:
      return state;
  }
};

export default reducer;
