import React from 'react';
import PropTypes from "prop-types";
import Nav from "react-bootstrap/Nav";

import './externalNavigationItem.scoped.scss';

const externalNavigationItem = props => {
  const liClasses = ["navigationItem"];
  liClasses.push(props.liClass);

  const linkClasses = ["navigationItemLink", "nav-iconized"];
  linkClasses.push(props.linkClass);

  return (
    <Nav.Item as="li" className={liClasses.join(' ')}>
      <Nav.Link
        href={props.link}
        className={linkClasses.join(' ')}
        target={props.target}
        active={false}
      >
        <i className="material-icons">{props.iconName}</i>
        <span>{props.children}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

externalNavigationItem.propTypes = {
  link: PropTypes.string,
  target: PropTypes.string,
  iconName: PropTypes.string,
};

export default externalNavigationItem;
