
const nl = {
  // Common
  "Close": "Sluiten",

  // Error modal.
  "Error": "Fout",

  // Register successful
  "Back to login page": "Terug naar aanmelden",
  "Registration successful": "Registratie succesvol ontvangen.",
  "Registration sent": "Uw registratie wordt door een beheerder nagekeken. Indien goedgekeurd ontvangt u een mail met uw login informatie.",

  // Pdf viewer modal.
  "View": "Bekijken",

  // Auth refresh modal.
  "Extend your session": "Sessie verlengen?",
  "Would you like to extend your session": "Uw sessie vervalt bijna. Wenst u uw sessie te verlengen?",
  "Extend": "Verlengen",
  "Do not extend": "Niet verlengen",

}

export default nl;
