import React from "react";
import PropTypes from "prop-types";

import "./flexTblData.scss";

const FlexTblData = props => {

  return (
    <div className={`data ${props.cls}`}>
      <span className="label">{props.label}</span>
      {props.children
        ? <span className="value">{props.children}</span>
        : null
      }
    </div>
  )
}

FlexTblData.propTypes = {
  label: PropTypes.string,
};

export default FlexTblData;
