import React from "react";
import {connect} from "react-redux";
import {useTranslation} from 'react-i18next';
import Helmet from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import sociopackConfig from "../../config/sociopack.config";
import Tile from "../UI/Tile/Tile";

import './home.scss';
import PropTypes from "prop-types";

const Home = props => {
  const {t} = useTranslation('pages');

  return (
    <>
      <Helmet>
        <title>{t('Dashboard')}{sociopackConfig.app.titleSuffix}</title>
        <link rel="canonical" href={sociopackConfig.app.baseURL + t('routes:/')} />
        <meta name="description" content={t('Dashboard')} />
      </Helmet>
      <div className="home">
        <h1>{t("Dashboard")}</h1>
        <Row className="greeting">
          <Col xs>
            {props.greeting}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={4} className="card-wrap">
            <Tile
              title={t('My information')}
              subTitle={t('Overview of your profile information')}
              link={t('routes:/my-information')}
              linkText={t('Go to your profile')}
            />
          </Col>
          <Col xs={12} sm={6} md={4} className="card-wrap">
            <Tile
              title={t('My family')}
              subTitle={t('Information about your family')}
              link={t('routes:/my-family')}
              linkText={t('Go to your family information')}
            />
          </Col>
          <Col xs={12} sm={6} md={4} className="card-wrap">
            <Tile
              title={t('My queue')}
              subTitle={t('Information about your position in the queue')}
              link={t('routes:/my-queue')}
              linkText={t('Go to your queue')}
            />
          </Col>
          <Col xs={12} sm={6} md={4} className="card-wrap">
            <Tile
              title={t('My requests')}
              subTitle={t('Overview of your requests')}
              link={t('routes:/my-requests')}
              linkText={t('Go to your requests')}
            />
          </Col>
          <Col xs={12} sm={6} md={4} className="card-wrap">
            <Tile
              title={t('My documents')}
              subTitle={t('Overview of your documents')}
              link={t('routes:/my-documents')}
              linkText={t('Go to your documents')}
            />
          </Col>
          <Col xs={12} sm={6} md={4} className="card-wrap">
            <Tile
              title={t('My invoices')}
              subTitle={t('Overview of your invoices')}
              link={t('routes:/my-invoices')}
              linkText={t('Go to your invoices')}
            />
          </Col>
          <Col xs={12} sm={6} md={4} className="card-wrap">
            <Tile
              title={t('My appointments')}
              subTitle={t('Overview of your appointments')}
              link={t('routes:/my-appointments')}
              linkText={t('Go to your appointments')}
            />
          </Col>
        </Row>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    greeting: state.app.greeting,
  };
};

Home.propTypes = {
  greeting: PropTypes.string,
};

export default connect(mapStateToProps)(Home);
