const nl = {
  "/": "/",
  "/login": "/aanmelden",
  "/register": "/registreren",
  "/info": "/informatie",
  "/add": "/toevoegen",
  "/notifications": "/notificaties",
  "/help": "/help",
  "/logout": "/afmelden",
  "/my-information": "/mijn-gegevens",
  "/my-family": "/mijn-gezin",
  "/my-queue": "/mijn-wachtrij",
  "/my-requests": "/mijn-aanvragen",
  "/my-documents": "/mijn-documenten",
  "/my-invoices": "/mijn-facturen",
  "/my-appointments": "/mijn-afspraken",
  "/password-reset": "/wachtwoord-vergeten",
  "/not-found": "/pagina-niet-gevonden",
}

export default nl;
