import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import authReducer from './reducers/authReducer';
import registerReducer from './reducers/registerReducer';
import notificationReducer from './reducers/notificationReducer'
import helpReducer from './reducers/helpReducer';
import newsReducer from './reducers/newsReducer';
import initAppReducer from './reducers/initAppReducer';
import familyCompReducer from './reducers/familyCompReducer';
import documentsReducer from './reducers/documentsReducer';
import invoicesReducer from './reducers/invoicesReducer';
import dynFormReducer from './reducers/dynFormReducer';
import profileReducer from './reducers/profileReducer';
import queueReducer from './reducers/queueReducer';
import requestReducer from './reducers/requestReducer';
import appointmentReducer from './reducers/appointmentReducer';
import attachmentReducer from './reducers/attachmentReducer';

const rootReducer = combineReducers({
  app: initAppReducer,
  auth: authReducer,
  register: registerReducer,
  notifications: notificationReducer,
  help: helpReducer,
  news: newsReducer,
  familyComp: familyCompReducer,
  documents: documentsReducer,
  invoices: invoicesReducer,
  dynForms: dynFormReducer,
  profile: profileReducer,
  queue: queueReducer,
  requests: requestReducer,
  appointments: appointmentReducer,
  attachments: attachmentReducer,
});

const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk),
  )
);

export default store;
