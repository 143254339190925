import axios from '../../config/axios.config';

import * as actionTypes from './actionTypes';

export const registerStart = () => {
  return {
    type: actionTypes.REGISTER_START,
  };
};

export const registerSuccess = () => {
  return {
    type: actionTypes.REGISTER_SUCCESS,
  };
};

export const registerFail = error => {
  return {
    type: actionTypes.REGISTER_FAIL,
    error: error,
  };
};

export const register = (registerData) => {
  return dispatch => {
    dispatch(registerStart());
    const data = {
      firstname: registerData.firstname,
      lastname: registerData.lastname,
      email: registerData.email,
      phone: registerData.phone,
      nationalRegistryNumber: registerData.social_security,
      message: registerData.message
    };

    let url = '/portal/users';
    axios.post(url, data)
      .then(response => {
        dispatch(registerSuccess());
      })
      .catch(error => {
        dispatch(registerFail(error.response));
      });
  };
};

export const setRegisterRedirectPath = (path) => {
  return {
    type: actionTypes.SET_REGISTER_REDIRECT_PATH,
    path: path,
  };
};

export const registerReset = () => {
  return {
    type: actionTypes.REGISTER_RESET,
  };
}
