import axios from '../../config/axios.config';

import * as actionTypes from './actionTypes';

export const getInvoicesStart = () => {
  return {
    type: actionTypes.INVOICELIST_GET_START
  };
};

export const getInvoiceListSuccess = invoiceList => {
  return {
    type: actionTypes.INVOICELIST_GET_SUCCESS,
    invoiceList: invoiceList
  };
};

export const getInvoicesFail = (error) => {
  return {
    type: actionTypes.INVOICELIST_GET_FAIL,
    error: error
  };
};

export const getInvoiceSuccess = document => {
  return {
    type: actionTypes.INVOICE_GET_SUCCESS,
    currentDocument: document
  };
}

export const getInvoiceList = () => {

  return dispatch => {
    dispatch(getInvoicesStart());

    axios.get('/portal/invoices')
        .then(response => {
            dispatch(getInvoiceListSuccess(response.data.resources));
        })
        .catch(error => {
            dispatch(getInvoicesFail(error.response));
        });
  };
};

export const getInvoice = id => {

  return dispatch => {
    dispatch(getInvoicesStart());

    axios.get(`/portal/invoices/${id}`)
      .then(response => {
        dispatch(getInvoiceSuccess(response.data.resources));
      })
      .catch(error => {
        dispatch(getInvoicesFail(error.response));
      });
  };
};
