import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/store/utility';

const initialState = {
  error: null,
  loading: false,
  profile: null,
};

const profileGetStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const profileGetSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    profile: action.profile
  });
};

const profileGetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROFILE_GET_START: return profileGetStart(state, action);
    case actionTypes.PROFILE_GET_SUCCESS: return profileGetSuccess(state, action);
    case actionTypes.PROFILE_GET_FAIL: return profileGetFail(state, action);
    default:
      return state;
  }
};

export default reducer;
