import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Helmet from "react-helmet";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import * as actions from "../../store/actions";
import sociopackConfig from "../../config/sociopack.config";
import Tile from "../../components/UI/Tile/Tile";
import Spinner from "../../components/UI/Spinner/Spinner";
import Breadcrumbs from "../../components/Navigation/Breadcrumb/Breadcrumb";
import ErrorMessage from "../../components/UI/ErrorMessage/ErrorMessage";

import './dynFormList.scoped.scss';

const DynFormList = props => {
  const { t } = useTranslation('pages');
  const {dynFormsByCategory, onGetDynFormList} = props;

  useEffect(() => {
    onGetDynFormList();
  }, [onGetDynFormList])

  let formTiles = <Spinner />

  let errorMessage = null;
  if (props.error) {
    errorMessage = <ErrorMessage errorMsg={props.errorMessage} />;
  }

  if (!props.loading) {
    formTiles = <p>{t('No forms for you yet')}</p>;
    if (Object.keys(dynFormsByCategory).length > 0) {
      formTiles = [];
      formTiles.push(
        Object.keys(dynFormsByCategory)
          .map(formKey => {
            let catForms;
            if (Object.keys(dynFormsByCategory[formKey].forms).length > 0) {
              catForms = Object.keys(dynFormsByCategory[formKey].forms).map((formId, index) => {
                return (
                  <Col key={index} xs lg="4">
                    <Tile
                      title={dynFormsByCategory[formKey].forms[formId].name}
                      subTitle={dynFormsByCategory[formKey].forms[formId].description}
                      link={`${t('routes:/add')}/${formKey}/${formId}`}
                    />
                  </Col>
                );
              });
            }
            return (
              <div key={formKey}>
                <h2>{dynFormsByCategory[formKey].name}</h2>
                <Row>{catForms}</Row>
              </div>
            );
          })
      );
    }
  }

  const breadcrumbsConfig = [
    {to: t('routes:/'), linkText: t('Home')},
    {active: true, linkText: t('Request forms')},
  ];

  return (
    <>
      <Helmet>
        <title>{t('Request forms')}{sociopackConfig.app.titleSuffix}</title>
        <link rel="canonical" href={sociopackConfig.app.baseURL + t("routes:/add")} />
        <meta name="description" content={t('Request forms')} />
      </Helmet>
      <div className="contact">
        <h1>{t("Request forms")}</h1>
        <Breadcrumbs config={breadcrumbsConfig} />
        {errorMessage}
        {formTiles}
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.dynForms.loading,
    error: state.dynForms.error !== null,
    errorMessage: (state.dynForms.error !== null) ? state.dynForms.error.data : null,
    dynFormsByCategory: state.dynForms.dynFormsByCategory,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetDynFormList: () => dispatch(actions.getDynFormList()),
  }
}

DynFormList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  dynFormsByCategory: PropTypes.object,
  onGetDynFormList: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DynFormList);
