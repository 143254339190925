import React from "react";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import Badge from "react-bootstrap/Badge";
import DateFormatted from "../../components/UI/DateFormatted/DateFormatted";
import FlexTbl from "../../components/UI/FlexTable/FlexTbl";

const QueueListItem = props => { 
  const {t} = useTranslation('pages');
  return (
    <FlexTbl.Row>
      <FlexTbl.Data label={t(props.tblConfig.headers[0].name)} cls="data-col-1">{props.inschrijvingsnr}</FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[1].name)} cls="data-col-2">{props.positie}</FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[2].name)} cls="data-col-3">{props.slaapkamers}</FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[3].name)} cls="data-col-4">{props.minBezetting}</FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[4].name)} cls="data-col-5">{props.maxBezetting}</FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[5].name)} cls="data-col-6"><DateFormatted format="DD-MM-YYYY HH:mm" date={props.schrDatum} /></FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[6].name)} cls="data-col-7">{props.gemeente}</FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[7].name)} cls="data-col-8">{props.wijk}</FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[8].name)} cls="data-col-9">{props.groep}</FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[9].name)} cls="data-col-10">{props.type}</FlexTbl.Data>
      <FlexTbl.Data label={t(props.tblConfig.headers[10].name)} cls="data-col-11">{props.inschrnr}</FlexTbl.Data>
    </FlexTbl.Row>
  );
}

QueueListItem.propTypes = {
  // register: PropTypes.string,
  // status: PropTypes.string,
  // position: PropTypes.number,
  // registeredAt: PropTypes.string,
  // registrationId: PropTypes.string,
  inschrijvingsnr: PropTypes.string,
  positie: PropTypes.number,
  slaapkamers: PropTypes.number,
  minBezetting: PropTypes.number,
  maxBezetting: PropTypes.number,
  schrDatum: PropTypes.string,
  gemeente: PropTypes.string,
  wijk: PropTypes.string,
  groep: PropTypes.string,
  type: PropTypes.string,
  inschrnr: PropTypes.string
}

export default QueueListItem;
