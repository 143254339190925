import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import Helmet from "react-helmet";
import PropTypes from "prop-types";

import * as actions from "../../store/actions";
import sociopackConfig from "../../config/sociopack.config";
import Spinner from "../../components/UI/Spinner/Spinner";
import FlexTbl from "../../components/UI/FlexTable/FlexTbl";
import DocumentItem from "./DocumentItem";
import Breadcrumbs from "../../components/Navigation/Breadcrumb/Breadcrumb";
import ErrorMessage from "../../components/UI/ErrorMessage/ErrorMessage";

import "./documentList.scss";

const DocumentList = props => {
  const { t } = useTranslation('pages');
  const {onGetDocumentList} = props;

  useEffect(() => {
    onGetDocumentList();
  }, [onGetDocumentList]);

  let documentList = <Spinner />;

  let errorMessage = null;
  if (props.error) {
    errorMessage = <ErrorMessage errorMsg={props.errorMessage} />;
  }

  const tblConfig = {
    headers: [
      {name: 'Name', type: 'text'},
      {name: 'Description', type: 'text'},
      {name: 'Date', type: 'date'},
      {name: 'Operations', type: 'text'},
    ],
  };

  if (!props.loading) {
    documentList = <p>{t('No documents for you yet')}</p>
    if (props.documents.length > 0) {
      documentList = props.documents.map(document => {
        return <DocumentItem
          key={document.id}
          id={document.id}
          legacyId={props.legacyId}
          name={document.name}
          description={document.description}
          type={props.enums[document.type.key][document.type.value].code}
          document={document.data}
          extension={document.extension}
          createdAt={document.createdAt}
          tblConfig={tblConfig}
        />
      });
    }
  }

  const breadcrumbsConfig = [
    {to: t('routes:/'), linkText: t('Home')},
    {active: true, linkText: t('My documents')},
  ];

  return (
    <>
      <Helmet>
        <title>{t('Documents')}{sociopackConfig.app.titleSuffix}</title>
        <link rel="canonical" href={sociopackConfig.app.baseURL + t('routes:/my-documents')} />
        <meta name="description" content={t('Documents')} />
      </Helmet>
      <h1>{t('Documents')}</h1>
      <Breadcrumbs config={breadcrumbsConfig} />
      {errorMessage}
      <div className="documentList">
        <FlexTbl cls={`flex-cols-${tblConfig.headers.length}`}>
          <FlexTbl.Head>
            <FlexTbl.Row>
              {tblConfig.headers.map((header, index) => {
                return <FlexTbl.Data cls={`data-col-${index+1}`} key={index} label={t(header.name)}/>
              })}
            </FlexTbl.Row>
          </FlexTbl.Head>
          <FlexTbl.Body>
            {documentList}
          </FlexTbl.Body>
        </FlexTbl>
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.documents.loading,
    error: state.documents.error !== null,
    errorMessage: (state.documents.error !== null) ? state.documents.error.data : null,
    documents: state.documents.documentList,
    enums: state.app.enums
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetDocumentList: () => dispatch( actions.getDocumentsList() ),
  };
}

DocumentList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  documents: PropTypes.array,
  enums: PropTypes.object,
  onGetDocumentList: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentList);
