import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/store/utility';

const initialState = {
  error: null,
  loading: false,
  requestList: []
};

const requestListGetStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const requestListGetSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    requestList: action.requestList
  });
};

const requestListGetFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch ( action.type ) {
    case actionTypes.REQUESTLIST_GET_START: return requestListGetStart(state, action);
    case actionTypes.REQUESTLIST_GET_SUCCESS: return requestListGetSuccess(state, action);
    case actionTypes.REQUESTLIST_GET_FAIL: return requestListGetFail(state, action);
    default:
      return state;
  }
};

export default reducer;
