const sociopackConfig = {
  api: {
    baseURL: process.env.REACT_APP_API_BASEURL,   //'https://sp2020test.dsocloud.be:50022',
    key: '', // If a key (hash) is needed to connect to the api.
  },
  app: {
    name: process.env.REACT_APP_NAME,
    titleSuffix: process.env.REACT_APP_TITLESUFFIX, // Start with a space.
    baseURL: process.env.REACT_APP_BASEURL,         // https://app-url.com --> without trailing slash
    basePath: process.env.REACT_APP_BASEPATH,       // Sub-folder on server.
    authRefreshReminderTime: 300, // The auth expiration - this value for authRefresh timeout
  }
}

export default sociopackConfig;
