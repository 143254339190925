import axios from '../../config/axios.config';

import * as actionTypes from './actionTypes';

export const getDocumentsStart = () => {
  return {
    type: actionTypes.DOCUMENTLIST_GET_START
  };
};

export const getDocumentsListSuccess = documentList => {
  return {
    type: actionTypes.DOCUMENTLIST_GET_SUCCESS,
    documentList: documentList
  };
};

export const getDocumentsFail = (error) => {
  return {
    type: actionTypes.DOCUMENTLIST_GET_FAIL,
    error: error
  };
};

export const getDocumentSuccess = document => {
  return {
    type: actionTypes.DOCUMENT_GET_SUCCESS,
    currentDocument: document
  };
}

export const getDocumentsList = () => {

  return dispatch => {
    dispatch(getDocumentsStart());

    axios.get('/portal/documents')
        .then(response => {
            dispatch(getDocumentsListSuccess(response.data.resources));
        })
        .catch(error => {
            dispatch(getDocumentsFail(error));
        });
  };
};

export const getDocument = id => {

  return dispatch => {
    dispatch(getDocumentsStart());

    axios.get('/portal/documents/' + id)
      .then(response => {
        dispatch(getDocumentSuccess(response.data.resources));
      })
      .catch(error => {
        dispatch(getDocumentsFail(error.response));
      });
  };
};
