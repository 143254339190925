/**
 * All page translations.
 */
const pagesNl = {
  // Home
  "Dashboard": "Dashboard",
  "My information": "Mijn gegevens",
  "Overview of your profile information": "Profieloverzicht",
  "Go to your profile": "Ga naar uw profiel",
  "My family": "Mijn gezin",
  "Information about your family": "Gezinsinformatie",
  "Go to your family information": "Ga naar gezinsinformatie",
  "My queue": "Mijn wachtrij",
  "Information about your position in the queue": "Uw wachtrijen",
  "Go to your queue": "Ga naar uw wachtrijen",
  "My requests": "Mijn aanvragen",
  "Overview of your requests": "Overzicht van uw aanvragen",
  "Go to your requests": "Ga naar uw aanvragen",
  "My documents": "Mijn documenten",
  "Overview of your documents": "Overzicht van uw documenten",
  "Go to your documents": "Ga naar uw documenten",
  "My invoices": "Mijn facturen",
  "Overview of your invoices": "Overzicht van uw facturen",
  "Go to your invoices": "Ga naar uw facturen",
  "My appointments": "Mijn afspraken",
  "Overview of your appointments": "Overzicht van uw afspraken",
  "Go to your appointments": "Ga naar uw afspraken",

  // Notifications
  "Notifications": "Notificaties",
  "No notifications for you yet": "Er zijn momenteel geen notificaties.",
  "Go to invoices": "Naar uw facturen",
  "Go to documents": "Naar uw documenten",
  "Read": "Gelezen",
  "Sent": "Verzonden",
  "New": "Nieuw",

  // Profile
  "Profile": "Profiel",
  "Name": "Naam",
  "Gender": "Geslacht",
  "Social security number": "Rijksregisternummer",
  "Birth date": "Geboortedatum",
  "Birth place": "Geboorteplaats",
  "Nationality": "Nationaliteit",
  "Address": "Adres",
  "Civil state": "Burgerlijke staat",
  "Co parenting": "Co-ouderschap",
  "Occupation": "Beroep",
  "Relation": "Relatie",
  "Visitation rights": "Bezoekrecht",
  "Email addresses": "E-mailadressen",
  "Income": "Inkomen",
  "Phone numbers": "Telefoonnummers",
  "Note": "Nota",
  "Change profile data": "Profielgegevens wijzigen",
  "Add family member": "Familielid toevoegen",
  "Remove family member": "Familielid verwijderen",
  "Change family member": "Familielid wijzigen",

  // Family composition
  "Family composition": "Gezinssamenstelling",
  "Family name": "Gezinsnaam",
  "Income prerequisite": "Voorwaarde inkomen",
  "Maximum income prerequisite": "Voorwaarde maximum inkomen",
  "You": "Jij",

  // Queue
  "Queue": "Wachtrij",
  "No queue yet": "U staat momenteel nog niet op een wachtlijst.",
  "Position": "Positie",
  "Registration Date": "Registratie datum",
  "Registration N°": "Registratie N°",
  "Status": "Status",

  // Requests
  "Requests": "Aanvragen",
  "No requests yet": "U heeft nog geen aanvragen.",

  // Documents
  "Documents": "Documenten",
  "No documents for you yet": "Er zijn nog geen documenten beschikbaar voor u.",
  "Document date": "Document datum",
  "Download": "Downloaden",
  "Date": "Datum",
  "Operations": "Bewerkingen",
  "Description": "Omschrijving",

  // Invoices
  "Invoices": "Facturen",
  "No invoices for you yet": "Er zijn nog geen facturen beschikbaar voor u.",
  "Amount": "Bedrag",
  "Pending": "Openstaand",
  "Due date": "Vervaldag",

  // Appointments
  "Appointments": "Afspraken",
  "No appointments for you yet": "Er zijn nog geen afspraken gepland.",

  // News
  "News": "Nieuws",
  "No news for you yet": "Er zijn nog geen nieuwsartikels beschikbaar.",

  // DynForms
  "Request forms": "Aanvraagformulieren",
  "No forms for you yet": "Er zijn nog geen aanvraagformulieren beschikbaar.",
}

export default pagesNl;
