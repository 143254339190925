/**
 * All form page translations.
 */
const en = {
  "Submit": "Submit-en",
  "Social security number": "Social security number",
  "Password": "Password",
  "First name": "First name",
  "Last name": "Last name",
  "Email": "Email",
  "Phone": "Phone",
  "Your message": "Your message",
  "Do you really want to log out?": "Do you really want to log out?",
  "Log out?": "Log out?",
  "Minimum length of 2 characters": "Minimum length of 2 characters.",
  "First name field is invalid": "First name field is invalid.",
  "Minimum length of 3 characters": "Minimum length of 3 characters.",
  "Last name field is invalid": "Last name field is invalid.",
  "Valid email address": "Valid email address.",
  "Phone number field is invalid": "Phone number field is invalid.",
  "Valid Belgian phone number without starting 0. 8 or 9 characters": "Valid Belgian phone number without starting 0. 8 or 9 characters.",
  "Example 123456-123-12": "Example 123456-123-12.",
  "Social security number field is invalid": "Social security number field is invalid.",
  "Your email address": "Your email address",
  "Email address field is invalid": "Email address field is invalid",
  "Message field is invalid": "Message field is invalid.",
  "Password field is invalid": "Password field is invalid.",
  "Forgot password": "Forgot password",
  "Reset password": "Reset password",
  "Password reset successful": "Password reset successful.",
  "Password reset mail sent": "An email has been sent to your email address that is linked with your account.",
  "Show": "Show",
  "Hide": "Hide",
  "Request form sent": "Your request has been successfully sent. We will inform you via email about updates concerning your request.",
  "Request updates": "You can also check for updates in the My Request section of this portal: ",
  "Go to requests": "Go to my requests",
}

export default en;
