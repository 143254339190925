import React from "react";
import FormElement from "../../components/UI/FormElement/FormElement";
import { updateObject } from '../store/utility';
import { conformToMask } from 'react-text-mask';

export const buildForm = (formConfig, setFormConfig) => {
  const inputChangedHandler = ( event, elementName ) => {
    let value = event.target.value;
    const mask = formConfig[elementName].mask;

    if (Array.isArray(mask)) {
      value = conformToMask(
        value,
        mask,
        {
          guide: false,
          keepCharPositions: false,
        }
      ).conformedValue
    }

    const updatedControls = updateObject( formConfig, {
      [elementName]: updateObject( formConfig[elementName], {
        value: value,
        valid: checkElementValidity( value, formConfig[elementName].validation ),
        touched: true
      })

    });
    setFormConfig(updatedControls);
  }

  const updateFormConfig = (elementId, updatedConfig) => {
    const updatedFormConfig = {
      ...formConfig,
      [elementId]: updatedConfig
    };
    setFormConfig(updatedFormConfig);
  }

  const formElementsArray = [];
  for ( let key in formConfig ) {
    formElementsArray.push({
      id: key,
      config: formConfig[key]
    });
  }

  return formElementsArray.map(formElement => (
    <FormElement
      key={formElement.id}
      formElementConfig={formConfig[formElement.id]}
      elementId={formElement.id}
      elementType={formElement.config.elementType}
      elementConfig={formElement.config.elementConfig}
      label={formElement.config.label}
      description={formElement.config.description}
      prefix={formElement.config.prefix}
      value={formElement.config.value}
      invalid={!formElement.config.valid}
      validation={formElement.config.validation}
      touched={formElement.config.touched}
      changed={(event) => inputChangedHandler(event, formElement.id)}
      updateFormConfig={updateFormConfig}
    />
  ));
}

export const checkElementValidity = (value, rules) => {
  let isValid = true;
  if ( !rules ) {
    return true;
  }

  if ( rules.required ) {
    isValid = value.trim() !== '' && isValid;
  }

  if ( rules.minLength ) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if ( rules.maxLength ) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if ( rules.isEmail ) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid;
  }

  if ( rules.isSocialSecurity ) {
    const pattern = /^[0-9]{6}-[0-9]{3}-[0-9]{2}$/;
    isValid = pattern.test(value) && isValid;
    // isValid = true && isValid;
  }

  if ( rules.isPhone ) {
    const pattern = /^[0-9]{9,10}$/;
    isValid = pattern.test(value) && isValid;
  }

  if ( rules.isNumeric ) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;
  }

  return isValid;
}

export const checkFormValidity = loginForm => {
  let isValid = true;
  Object.keys(loginForm).forEach(key => {
    isValid = (isValid && loginForm[key].valid);
  });
  return isValid;
}

export const getFormErrorMessages = loginForm => {
  let errorMessages = [];
  Object.keys(loginForm).forEach(key => {
    if (!loginForm[key].valid) {
      errorMessages.push(loginForm[key].errorMsg);
    }
  });
  return errorMessages;
}
