import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet'
import {connect} from "react-redux";
import PropTypes from "prop-types";

import * as actions from "../../store/actions";
import sociopackConfig from "../../config/sociopack.config";
import AppointmentListItem from "./AppointmentListItem";
import Spinner from "../../components/UI/Spinner/Spinner";
import Breadcrumbs from "../../components/Navigation/Breadcrumb/Breadcrumb";
import ErrorMessage from "../../components/UI/ErrorMessage/ErrorMessage";

import './appointmentList.scss';

const AppointmentList = props => {
  const {t} = useTranslation('pages');
  const {onGetAppointmentList} = props;

  useEffect(() => {
    onGetAppointmentList();
  }, [onGetAppointmentList]);

  let appointmentList = <Spinner />;

  let errorMessage = null;
  if (props.error) {
    errorMessage = <ErrorMessage errorMsg={props.errorMessage} />;
  }

  if (!props.loading) {
    appointmentList = <p>{t('No appointments for you yet')}</p>
    if (props.appointmentList.length > 0) {
      appointmentList = props.appointmentList.map((appointment, index) => {
        return <AppointmentListItem
          key={appointment.id}
          index={index}
          name={appointment.name}
          description={appointment.description}
          subject={appointment.workflow}
          category={appointment.category}
          form={appointment.form}
          formTemplateId={appointment.formTemplateId}
        />
      });
    }
  }

  const breadcrumbsConfig = [
    {to: t('routes:/'), linkText: t('Home')},
    {active: true, linkText: t('My appointments')},
  ];

  return (
    <>
      <Helmet>
        <title>{t('Appointments')}{sociopackConfig.app.titleSuffix}</title>
        <link rel="canonical" href={sociopackConfig.app.baseURL + t('routes:/my-appointments')} />
        <meta name="description" content={t('Appointments')} />
      </Helmet>
      <h1>{t('Appointments')}</h1>
      <Breadcrumbs config={breadcrumbsConfig} />
      {errorMessage}
      <div className="appointmentList">
        {appointmentList}
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.appointments.loading,
    error: state.appointments.error,
    errorMessage: (state.appointments.error !== null) ? state.appointments.error.data : null,
    appointmentList: state.appointments.appointmentList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAppointmentList: () => dispatch( actions.getAppointmentList()),
  };
}

AppointmentList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  appointmentList: PropTypes.array,
  onGetAppointmentList: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentList);
