import pagesEn from "./pages.en";
import pagesNl from "./pages.nl";
import commonEn from "./common.en";
import commonNl from "./common.nl";

const pages = {
  en: {
    ...commonEn,
    ...pagesEn,
  },
  nl: {
    ...commonNl,
    ...pagesNl,
  }
}

export default pages;
