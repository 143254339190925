/**
 * All link translations.
 */
const nl = {
  "Log in": "Aanmelden",
  "Register": "Registreren",
  "Notifications": "Notificaties",
  "Online help": "Online help",
  "Log out": "Afmelden",
  "Close": "Sluiten",
  "Home": "Home",
  "Info": "Informatie",
  "Add": "Toevoegen",
  "Forgot password": "Wachtwoord vergeten",
  "My Profile": "Mijn profiel",
  "Account": "Account",
}

export default nl;
