import axios from '../../config/axios.config';

import * as actionTypes from './actionTypes';

export const initAppStart = () => {
  return {
    type: actionTypes.INIT_APP_START
  };
};

export const initAppSuccess = data => {
  return {
    type: actionTypes.INIT_APP_SUCCESS,
    greeting: data.greeting,
    host: data.host,
    logo: data.logo,
    documentation: data.documentation,
    enums: data.enums
  };
};

export const initAppFail = error => {
  return {
    type: actionTypes.INIT_APP_FAIL,
    error: error
  };
};

export const initApp = () => {
  return dispatch => {
    dispatch(initAppStart());

    axios.get('/portal')
      .then(response => {
        // @Todo: remove timeout. Only added for testing the init spinner.
        // setTimeout(function() {
        //   dispatch(initAppSuccess(response.data));
        // }, 5000);
        dispatch(initAppSuccess(response.data));
      })
      .catch(error => {
        dispatch(initAppFail(error.response));
      });
  };
};
