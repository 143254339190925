import React from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";

import {checkDateValidity} from "../../../services/common/utility";

const DateFormatted = props => {

  if (checkDateValidity(props.date)) {
    return <Moment format={props.format}>{props.date}</Moment>;
  }
  return null;
}

DateFormatted.propTypes = {
  date: PropTypes.string,
  format: PropTypes.string,
};

export default DateFormatted;
