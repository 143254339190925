import React from "react";
import "./flexTblBody.scss";

const FlexTblBody = props => {

  return (
    <div className="listBody">
      {props.children}
    </div>
  )
}

export default FlexTblBody;
