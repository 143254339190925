import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/store/utility';

const initialState = {
  greeting: null,
  hostShm: null,
  logo: null,
  documentationUrl: null,
  enums: null,
  error: null,
  loading: false,
};

const initAppStart = (state, action) => {
  return updateObject( state, { error: null, loading: true } );
};

const initAppSuccess = (state, action) => {
  return updateObject( state, {
    greeting: action.greeting,
    hostShm: action.host,
    logo: action.logo,
    documentationUrl: action.documentation,
    enums: action.enums,
    error: null,
    loading: false
  });
};

const initAppFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch ( action.type ) {
    case actionTypes.INIT_APP_START: return initAppStart(state, action);
    case actionTypes.INIT_APP_SUCCESS: return initAppSuccess(state, action);
    case actionTypes.INIT_APP_FAIL: return initAppFail(state, action);
    default:
      return state;
  }
};

export default reducer;
