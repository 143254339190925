/**
 * All form page translations.
 */
const nl = {
  "Submit": "Verzenden",
  "Social security number": "Rijksregisternummer",
  "Password": "Wachtwoord",
  "Log in": "Aanmelden",
  "Register": "Registreren",
  "First name": "Voornaam",
  "Last name": "Achternaam",
  "Email": "E-mailadres",
  "Phone": "Telefoon",
  "Your message": "Uw bericht",
  "Do you really want to log out?": "Bent u zeker?",
  "Log out?": "Afmelden?",
  "Minimum length of 2 characters": "Minimum 2 karakters.",
  "First name field is invalid": "Voornaam veld is  ongeldig.",
  "Minimum length of 3 characters": "Minimum 3 karakters.",
  "Last name field is invalid": "Achternaam veld is ongeldig.",
  "Valid email address": "Een geldig e-mailadres.",
  "Phone number field is invalid": "Telefoonnummer veld is ongeldig.",
  "Valid Belgian phone number without starting 0, 8 or 9 characters": "Een geldig telefoonnummer zonder te starten met 0. 8 of 9 karakters.",
  "Example 123456-123-12": "Voorbeeld 123456-123-12.",
  "Social security number field is invalid": "Rijksregisternummer veld is ongeldig.",
  "Your email address": "Uw e-mailadres",
  "Email address field is invalid": "E-mailadres veld is ongeldig",
  "Message field is invalid": "Bericht veld is ongeldig.",
  "Minimum length of 6 characters": "Minimum 6 karakters.",
  "Password field is invalid": "Wachtwoord veld is ongeldig.",
  "Forgot password": "Wachtwoord vergeten",
  "Reset password": "Wachtwoord resetten",
  "Password reset successful": "Wachtwoord reset succesvol.",
  "Password reset mail sent": "Er werd een e-mail verzonden naar het e-mailadres dat gekoppeld is met uw rijksregisternummer voor verdere instructies.",
  "Show": "Weergeven",
  "Hide": "Verbergen",
  "Request form sent": "Uw aanvraag werd succesvol verzonden. We zullen u via email op de hoogte brengen van status wijzigingen over deze aanvraag.",
  "Request updates": "U kan ook de status van uw aanvraag bekijken bij 'Mijn aanvragen' op dit portaal: ",
  "Go to requests": "Naar mijn aanvragen",
}

export default nl;
