import React from "react";
import {Link} from "react-router-dom";
import Card from 'react-bootstrap/Card';
import PropTypes from "prop-types";

const Tile = props => {

  return (
    <Card>
      <Card.Link as={Link} to={props.link}>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{props.subTitle}</Card.Subtitle>
          <Card.Text>{props.children}</Card.Text>
          {/*<Card.Link as={Link} to={props.link}>{props.linkText}</Card.Link>*/}
        </Card.Body>
      </Card.Link>
    </Card>
  )
}

Tile.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

export default Tile;
