import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/store/utility';

const initialState = {
  error: null,
  loading: false,
  family: []
};

const familyCompStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const familyCompSuccess = (state, action) => {
  return updateObject( state, {
    error: null,
    loading: false,
    family: action.family
  } );
};

const familyCompFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};

const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.FAMILY_COMP_START: return familyCompStart(state, action);
    case actionTypes.FAMILY_COMP_SUCCESS: return familyCompSuccess(state, action);
    case actionTypes.FAMILY_COMP_FAIL: return familyCompFail(state, action);
    default:
      return state;
  }
};

export default reducer;
