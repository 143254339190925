/**
 * All link translations.
 */
const en = {
  "Log in": "Log in",
  "Register": "Register",
  "Notifications": "Notifications",
  "Online help": "Online help",
  "Log out": "Log out",
  "Close": "Close",
  "Home": "Home",
  "Info": "Info",
  "Add": "Add",
  "Forgot password": "Forgot password",
  "My Profile": "My Profile",
  "Account": "Account",
}

export default en;
