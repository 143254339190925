import React, {useEffect} from "react";
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet'
import {connect} from "react-redux";
import PropTypes from 'prop-types';

import * as actions from "../../store/actions";
import sociopackConfig from "../../config/sociopack.config";
import NotificationListItem from "./NotificationListItem";
import Spinner from "../../components/UI/Spinner/Spinner";
import ErrorMessage from "../../components/UI/ErrorMessage/ErrorMessage";

import './notificationList.scss';

const NotificationList = props => {
  const { t } = useTranslation('pages');
  const {onGetNotifications} = props;

  useEffect(() => {
    onGetNotifications();
  }, [onGetNotifications]);

  let notifications = <Spinner />;

  let errorMessage = null;
  if (props.error) {
    errorMessage = <ErrorMessage errorMsg={props.errorMessage} />;
  }

  if (!props.loading) {
    notifications = <p>{t('No notifications for you yet')}</p>
    if (props.notifications.length > 0) {
      notifications = props.notifications.map(notification => {
        return <NotificationListItem
          key={notification.id}
          content={notification.content}
          link={notification.link}
          status={notification.status}
          module={notification.module}
          notified={notification.notified}
          read={notification.read}
          id={notification.referenceId}
        />
      });
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('Notifications')}{sociopackConfig.app.titleSuffix}</title>
        <link rel="canonical" href={sociopackConfig.app.baseURL + t('routes:/notifications')} />
        <meta name="description" content={t('Notifications')} />
      </Helmet>
      <div className="notificationList">
        <h1>{t('Notifications')}</h1>
        {errorMessage}
        {notifications}
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return {
    loading: state.notifications.loading,
    error: state.notifications.error !== null,
    errorMessage: (state.notifications.error !== null) ? state.notifications.error.data : null,
    notifications: state.notifications.notifications,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetNotifications: () => dispatch( actions.getNotificationList() ),
  };
}

NotificationList.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.bool,
  notifications: PropTypes.array,
  onGetNotifications: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationList);
